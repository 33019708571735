<div class="header">
    <h4><span> Task Details </span></h4>
</div>
<div *ngIf="taskDetail">
<div class="p-field p-grid ">
    <label  class="p-col-6"> <div style="width: 100%;text-align: right;color: var(--white); font-weight: 700;">Operation name:</div></label>
    <div class="p-col">
     <label>ocpp1.6 / {{taskDetail.operationName}}</label>
    </div>
</div>
<div class="p-field p-grid ">
    <label  class="p-col-6"> <div style="width: 100%;text-align: right;color: var(--white); font-weight: 700;">Origin:</div></label>
    <div class="p-col">
     <label>{{taskDetail.origin}} ({{taskDetail.caller}})</label>
    </div>
</div>
<div class="p-field p-grid ">
    <label  class="p-col-6"> <div style="width: 100%;text-align: right;color: var(--white); font-weight: 700;">Start timestamp:</div></label>
    <div class="p-col">
     <label>{{taskDetail.startTimestamp | date: "yyyy-MM-dd hh:mm:ss"}}</label>
    </div>
</div>
<div class="p-field p-grid ">
    <label  class="p-col-6"> <div style="width: 100%;text-align: right;color: var(--white); font-weight: 700;">End timestamp:</div></label>
    <div class="p-col">
     <label>{{taskDetail.endTimestamp | date: "yyyy-MM-dd hh:mm:ss"}}</label>
    </div>
</div>
<div class="p-field p-grid ">
    <label  class="p-col-6"> <div style="width: 100%;text-align: right;color: var(--white); font-weight: 700;">No of charge point requests:</div></label>
    <div class="p-col">
     <label>{{taskDetail.noOfChargePoint}}</label>
    </div>
</div>
<div class="p-field p-grid ">
    <label  class="p-col-6"> <div style="width: 100%;text-align: right;color: var(--white); font-weight: 700;">No of responses:</div></label>
    <div class="p-col">
     <label>{{taskDetail.responseCount}}</label>
    </div>
</div>
<div class="p-field p-grid ">
    <label  class="p-col-6"> <div style="width: 100%;text-align: right;color: var(--white);font-weight: 700;">No of Error:</div></label>
    <div class="p-col">
     <label>{{taskDetail.errorCount}}</label>
    </div>
</div>
<div class="header">
    <h4><span> Task Result </span></h4>
</div>

<p-table #dt [columns]="cols" [value]="data" [paginator]="true" [rows]="50" [autoLayout]="true">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns">
              <th [ngStyle]="{'width': col.width}">
                  <ng-container [ngSwitch]="col.field">
                     <ng-container *ngSwitchDefault>{{ col.header }}</ng-container>
                   </ng-container>
              </th>
        </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-columns="columns" let-rowIndex="rowIndex">
        <tr>
            <ng-container *ngFor="let col of columns" >
            <td>
                <ng-container [ngSwitch]="col.field">
                    <ng-container *ngSwitchDefault>
                        {{data[col.field]}}
                    </ng-container>
                </ng-container>
            </td>
            </ng-container>
        </tr>
    </ng-template>
</p-table>
<!-- <div class="p-field p-grid ">
    <label  class="p-col-6"> <div style="width: 100%;text-align: right;color: var(--white); font-weight: 700;">Charge Box Id:</div></label>
    <div class="p-col">
     <label>1</label>
    </div>
</div>
<div class="p-field p-grid ">
    <label  class="p-col-6"> <div style="width: 100%;text-align: right;color: var(--white); font-weight: 700;">Response:</div></label>
    <div class="p-col">
     <label>{{taskDetail.result.chargepoint0123.response}}</label>
    </div>
</div>
<div class="p-field p-grid ">
    <label  class="p-col-6"> <div style="width: 100%;text-align: right;color: var(--white);font-weight: 700;">Error:</div></label>
    <div class="p-col">
     <label>{{taskDetail.result.chargepoint0123.error}}</label>
    </div>
</div> -->
</div>



