import { Component, OnInit } from '@angular/core';
import { country } from '../../model/country';
import { SelectItem } from 'primeng/api';
import { UsersService } from '../../services/users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { HomeComponent } from '../home/home.component';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  
  userId: String = "";
  cols: any[];
  data ;
  showAddNew=  false;
  sexOption:  SelectItem[];
  birthday= new Date();
  totalCharger = 0
  availableCount=0
  connectedCount=0
  chargingCount=0
  completeCount=0
  unavailableCount=0
  faultedCount=0
  reservedCount=0
  countryList: { label: string; value: string; }[];
  userForm =  {
    "address": {
      "city": "",
      "country": "IN",
      "houseNumber": "",
      "street": "",
      "zipCode": ""
    },
    "birthDay":0,
    "email": "", 
    // "fleetCode": "string",
    // "fleetId": 0,
    // "fleetName": "string",
    // "isFleetVerified": true,
    "name": "", 
    "note": "",
    "phone": "", 
    "sex": "FEMALE",
    "tenantId": 1,
  }



  constructor(private usersService : UsersService,private spinner: NgxSpinnerService,private homeService: HomeService) { 
  }


  ngOnInit(): void {
    this.spinner.show()
    this.getUser()
    this.homeService.getHomeData().subscribe(res=>{
      if(res['status']=='SUCCESS')
       {
         if(res['response']['statusCountMap'])
        {
         this.availableCount = res['response']['statusCountMap']['Available'] ? res['response']['statusCountMap']['Available'] : 0;
         this.connectedCount = res['response']['statusCountMap']['Connected'] ? res['response']['statusCountMap']['Connected']: 0;
         this.chargingCount = res['response']['statusCountMap']['Charging'] ? res['response']['statusCountMap']['Charging']: 0; 
         this.completeCount =res['response']['statusCountMap']['Complete'] ? res['response']['statusCountMap']['Complete']: 0; 
         this.unavailableCount = res['response']['statusCountMap']['Unavailable'] ? res['response']['statusCountMap']['Unavailable']: 0; 
         this.faultedCount = res['response']['statusCountMap']['Faulted'] ? res['response']['statusCountMap']['Faulted']: 0; 
         this.reservedCount = res['response']['statusCountMap']['Reserved'] ? res['response']['statusCountMap']['Reserved']: 0; 
         this.totalCharger =  this.availableCount + this.connectedCount + this.chargingCount + this.completeCount + this.unavailableCount + this.faultedCount + this.reservedCount
        }
       }
       this.spinner.hide()
    })
    this.cols = [
      { field: 'sno', header: 'S No.',width : '67px' },
      { field: 'userPk', header: 'User Id' },
      { field: 'name', header: 'Name' },
      { field: 'email', header: 'Email',width : '220px' },
      { field: 'phone', header: 'Phone' },
      { field: 'walletBalance', header: 'Wallet Balance',width : '150px'  },
      { field: 'lastTransaction', header: 'Last Transaction' },
      { field: 'deleteUser', header: 'Delete User' },
      // { field: 'button', header: '' }
  ];
    this.sexOption = [{label: 'Female', value: 'FEMALE'},
                      {label: 'Male', value: 'MALE'}]
    
    this.countryList = country;  
  }
  getUser(){
    this.usersService.getAllUser().subscribe(res=>{
     //console.log(res)
     this.data = res['response']
    
   })
  }
  openAddNew(){
    this.userForm =  {
      "address": {
        "city": "",
        "country": "IN",
        "houseNumber": "",
        "street": "",
        "zipCode": ""
      },
      "birthDay":0,
      "email": "", 
      // "fleetCode": "string",
      // "fleetId": 0,
      // "fleetName": "string",
      // "isFleetVerified": true,
      "name": "", 
      "note": "",
      "phone": "", 
      "sex": "FEMALE",
      "tenantId": 1,
    }
    this.showAddNew = !this.showAddNew;
  }
  addUser(){
    this.userForm.birthDay=this.birthday.getTime();
    if(this.userForm.name == "" || this.userForm.email=="" || this.userForm.phone == "")
    {
      Swal.fire(
        'Required!',
        'Name, email and phone are required.',
        'error'
      )
      return
    }
    if(this.userForm.phone.toString().length != 10)
    {   Swal.fire(
        'Required!',
        'Enter valid Phone No.',
        'error'
      )
      return
    }
    this.userForm.name = this.userForm.name.trim();
    this.userForm.email = this.userForm.email.trim();
    this.userForm.address.city = this.userForm.address.city.trim();
    this.userForm.address.houseNumber =this.userForm.address.houseNumber.trim();
    this.userForm.address.street =this.userForm.address.street.trim();
    this.userForm.address.zipCode =this.userForm.address.zipCode.trim();
     
    this.usersService.update(this.userForm).subscribe(res=>{
      if(res['status']=='SUCCESS')
      {Swal.fire(
        'Successfull!',
        'User added successfully',
        'success'
      )
      this.showAddNew = !this.showAddNew;
      this.getUser()
    }
      else{
        Swal.fire(
          'Some thing went Wrong!',
          res['errorMessage'],
          'error'
        )
      }
    },error =>{
      Swal.fire(
        'Some thing went Wrong!',
        error.error.errorMessage,
        'error'
      )
    })
  }
  deleteUser(userPk){
    Swal.fire({
      title: 'Are you sure you want to delete the User: '+userPk+'?',
      // text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.usersService.deleteUser(userPk).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'User id: '+userPk+' has been deleted.',
            'success'
          )
          this.getUser()
        },error =>{
          Swal.fire(
            'Some thing went Wrong!',
            error.error.errorMessage,
            'error'
          )
        })
      }
    })
  }

}
