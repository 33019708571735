import { Component, OnInit } from '@angular/core';
import { ChargePointService } from '../../services/charge-point.service';
import { country } from '../../model/country';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-charge-point',
  templateUrl: './charge-point.component.html',
  styleUrls: ['./charge-point.component.css']
})
export class ChargePointComponent implements OnInit {

  
  chargeBoxId;
  description;
  selectedOcppVersion ='ALL';
  selectedHeartBeatPeriod ='ALL';
  cols: any[];
  data ;
  showAddNew=  false;
  showGraph = false;
  OcppVersions: { label: string; value: string; }[];
  HeartBeatPeriods: { label: string; value: string; }[];
  totalCharger = 0
  availableCount=0
  connectedCount=0
  chargingCount=0
  completeCount=0
  unavailableCount=0
  faultedCount=0
  reservedCount=0
  preparingCount=0
  chargePointForm =  {
    "address": {
      "city": "",
      "country": "",
      "houseNumber": "",
      "street": "",
      "zipCode": ""
    },
    "chargeBoxId": "",
    "description": "",
    "locationLatitude": 0,
    "locationLongitude": 0,
    "note": "",
    "tenantId": 1
  }
  selectedCountry = 'IN'
  countryList: { label: string; value: string; }[];
  chargerBoxIdGraph: any;
  constructor(private chargePointService: ChargePointService ,private spinner: NgxSpinnerService,private homeService: HomeService) { 
  }

  ngOnInit(): void {
    this.spinner.show();
    this.chargePointService.getChargePoints().subscribe(res=>{
      this.data= res['response']['cpList']
      this.spinner.hide();
    })
    this.homeService.getHomeData().subscribe(res=>{
      if(res['status']=='SUCCESS')
       {
         if(res['response']['statusCountMap'])
        {
         this.availableCount = res['response']['statusCountMap']['Available'] ? res['response']['statusCountMap']['Available'] : 0;
         this.connectedCount = res['response']['statusCountMap']['Connected'] ? res['response']['statusCountMap']['Connected']: 0;
         this.preparingCount = res['response']['statusCountMap']['Preparing'] ? res['response']['statusCountMap']['Preparing']: 0;
         this.chargingCount = res['response']['statusCountMap']['Charging'] ? res['response']['statusCountMap']['Charging']: 0; 
         this.completeCount =res['response']['statusCountMap']['Finishing'] ? res['response']['statusCountMap']['Finishing']: 0; 
         this.unavailableCount = res['response']['statusCountMap']['Unavailable'] ? res['response']['statusCountMap']['Unavailable']: 0; 
         this.faultedCount = res['response']['statusCountMap']['Faulted'] ? res['response']['statusCountMap']['Faulted']: 0; 
         this.reservedCount = res['response']['statusCountMap']['Reserved'] ? res['response']['statusCountMap']['Reserved']: 0; 
         this.totalCharger = res['response']['numChargeBoxes']
        }
       }
    })
    this.cols = [
      { field: 'sno', header: 'S No.', width : '67px' },
      { field: 'chargerDisplayName', header: 'Charger', width : '150px'},
      { field: 'chargingStation', header: 'Charging Station' },
      { field: 'description', header: 'Description' },
      { field: 'ocppProtocol', header: 'OCPP Protocol',width : '100px' },
      { field: 'chargeBoxId', header: 'Charge Box Id', width : '200px'},
      { field: 'lastHeartBeat', header: 'Last Heartbeat',width : '200px' },
      { field: 'deleteUser', header: 'Delete User' }
  ];
  this.OcppVersions =[{label: 'All', value: 'ALL'},
                      {label: 'OCPP1.2', value: 'V_12'},
                      {label: 'OCPP1.5', value: 'V_15'},
                      {label: 'OCPP1.6', value: 'V_16'}]
  this.HeartBeatPeriods =[{label: 'All', value: 'ALL'},
                          {label: 'Today', value: 'TODAY'},
                          {label: 'Yesterday', value: 'YESTERDAY'},
                          {label: 'Earlier', value: 'EARLIER'}]
  this.countryList = country;
  }

  openAddNew(){
    this.showAddNew = true;
  }
  getCharger(){
   let chargeBoxId ,description ,heartbeatPeriod ,ocppVersion; 
   chargeBoxId = this.chargeBoxId ? this.chargeBoxId : '';
   description = this.description ? encodeURI(this.description) : '';
   heartbeatPeriod = this.selectedHeartBeatPeriod  ? this.selectedHeartBeatPeriod : '';
   ocppVersion = this.selectedOcppVersion == 'ALL' ? '' : this.selectedOcppVersion;

   this.chargePointService.getChargePoints(chargeBoxId ,description ,heartbeatPeriod ,ocppVersion).subscribe(res=>{
    // console.log(res)
    this.data= res['response']['cpList']
   })
  }
  saveNewCharger(){
    // console.log(this.countryList)
    // console.log(this.selectedCountry)
    this.chargePointForm.address.country =this.selectedCountry
    // console.log(this.chargePointForm.address.country)
    // console.log(this.chargePointForm)
    this.chargePointService.addChargePoint(this.chargePointForm).subscribe(res=>{
      // console.log(res)
      if(res['errorCode']==200)
     {  
      Swal.fire(
        'Success!',
        'ChargeBox added successfully',
        'success'
      )
      this.chargePointService.getChargePoints().subscribe(res=>{
        // console.log(res)
        this.data= res['response']['cpList']
      })
       this.showAddNew = false;
      }
      else
      {  Swal.fire(
        'Some thing went Wrong!',
        res['errorMessage'],
        'error'
      )
      }
    },error=>{
      // console.log(error)
    })
  }
  hideDialog(){
    this.showAddNew = false;
  }
  deleteChargeBox(data){
    Swal.fire({
      title: 'Are you sure you want to delete the Charge box '+data.chargeBoxId+'?',
      // text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.chargePointService.deleteChargePoint(data.chargeBoxId).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'ChargeBox '+data.chargeBoxId+' has been deleted.',
            'success'
          )
          this.chargePointService.getChargePoints().subscribe(res=>{
            // console.log(res)
            this.data= res['response']['cpList']
          })
        },error =>{
          // console.log(error)
          Swal.fire(
            'Some thing went Wrong!',
            error.error.errorMessage,
            'error'
          )
        })
      }
    })

  }
  viewGraph(data){
    this.chargerBoxIdGraph = data.chargerDisplayName
    this.showGraph = !this.showGraph
  }
}
