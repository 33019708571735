import { HttpHeaders } from '@angular/common/http';

export class Headers {

  private static HEADERS: HttpHeaders;

  public static get headers() {
    return Headers.HEADERS;
  }

  public static addHeaders(headers) {
    if (!Headers.HEADERS) {
      Headers.HEADERS = new HttpHeaders();
    }
    //Headers.HEADERS = Headers.HEADERS.append('Authorization', 'Bearer 05RjysfPiEtmWxvSG5KKeV28VZHjbYcFFaH1R6E9U0');
    Headers.HEADERS = Headers.HEADERS.append(headers.key, headers.value);
  }
  public static clearHeaders() {
    Headers.HEADERS = null;
  }
}
