import { Injectable } from '@angular/core';
import { Headers } from '../model/api.headers';
import { HttpClient } from '@angular/common/http';
import { API_DELETE_FINISHED_TASK, API_FETCH_TASK,API_GET_TASK_DETAILS } from '../config/index';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(private http: HttpClient) { }

  getTasks(){
    return this.http.get(API_FETCH_TASK,{headers: Headers.headers})
  }
  clearFinishedTask(){
    let body ={}
    return this.http.post(API_DELETE_FINISHED_TASK,body,{headers: Headers.headers})
  }
  getTaskDetail(taskId){
   return this.http.get(API_GET_TASK_DETAILS+taskId,{headers: Headers.headers})
  }
}
