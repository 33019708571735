<header *ngIf="dataSharingService.isUserLoggedIn.value" [isUserLogin]="isUserLogin" [name]="dataSharingService.name"></header>
<div *ngIf="!dataSharingService.isUserLoggedIn.value" >

    <div style="height: 100vh; width: 100vw; background-color: var(--grey-3);">
        <div class="login-container">
            <div class="logo">
             <img class="logo-img" src="../../assets/logo1.png" height="90px">
            </div>
            <div class="d-flex justify-content-center form_container">
            <form #f='ngForm' (ngSubmit)='login(f)' autocomplete="off">
              <div class="input-group mb-3">
                  <div class="input-group-append">
                      <span class="input-group-text"><i class="fas fa-user"></i></span>
                  </div>
                  <input pInputText type="text" name="username" class="form-control input_user" value="" placeholder="username" [(ngModel)]="userName">
              </div>
              <div class="input-group mb-2">
                  <div class="input-group-append">
                      <span class="input-group-text"><i class="fas fa-key"></i></span>
                  </div>
                  <input pInputText type="password" name="password" class="form-control input_pass" value="" placeholder="password" [(ngModel)]="password">
              </div>
              <div class="form-group">
                  <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="customControlInline">
                      <label class="custom-control-label" for="customControlInline">Remember me</label>
                  </div>
              </div>
                  <div class="d-flex justify-content-center mt-3 login_container">
           <button type="submit" name="button" class="btn login_btn">Login</button>
         </div>
          </form>
        </div>
        </div>
      </div>
</div>