import { Injectable } from '@angular/core';
import { Headers } from '../model/api.headers';
import { HttpClient } from '@angular/common/http';
import { API_FETCH_CHARGER_POINT,API_POST_CHANGE_AVAILABILITY,
         API_POST_CANCEL_RESERVSTION,API_POST_CHANGE_CONFIGURATION,
         API_POST_CLEAR_CACHE, API_POST_GET_CONFIGURATION,
         API_POST_GET_LOCAL_LIST_VERSION, API_POST_REMOTE_START_TRANSACTION,
         API_POST_REMOTE_STOP_TRANSACTION,API_POST_RESERVE_NOW,
         API_POST_RESET,API_POST_TRIGGER_MESSAGE,
         API_POST_UNLOCK_CHARGER,API_FETCH_ACTIVE_RESERVATION_FOR_CHARGER,
         API_FETCH_OCPP_LIST,API_POST_SEND_LOCAL_LIST,API_FETCH_ACTIVE_TRANSACTION_FOR_CHARGER } from '../config/index';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private http : HttpClient) { }

  getChargers(){
    return this.http.get(API_FETCH_CHARGER_POINT, {headers: Headers.headers})
  }
  getActiveReservationByCharger(charger){
    return this.http.get(API_FETCH_ACTIVE_RESERVATION_FOR_CHARGER+charger,{headers: Headers.headers} )
  }
  getActionTransactionByCharger(charger){
    return this.http.get(API_FETCH_ACTIVE_TRANSACTION_FOR_CHARGER+charger,{headers : Headers.headers})
  }
  getOCPPTag(){
    return this.http.get(API_FETCH_OCPP_LIST,{headers: Headers.headers})
  }
  performChangeAvailability(body){
    return this.http.post(API_POST_CHANGE_AVAILABILITY,body,{headers: Headers.headers})
  }
  performCancelReservation(body){
    return this.http.post(API_POST_CANCEL_RESERVSTION,body,{headers: Headers.headers})
  }
  performChangeConfiguration(body){
    return this.http.post(API_POST_CHANGE_CONFIGURATION,body,{headers: Headers.headers})
  }
  performClearCache(body){
    return this.http.post(API_POST_CLEAR_CACHE,body,{headers: Headers.headers})
  }
  performGetConfig(body){
     return this.http.post(API_POST_GET_CONFIGURATION,body,{headers: Headers.headers})
  }
  performGetLocalListVersion(body){
    return this.http.post(API_POST_GET_LOCAL_LIST_VERSION,body, {headers: Headers.headers})
  }
  performRemoteStartTransaction(body){
    return this.http.post(API_POST_REMOTE_START_TRANSACTION,body,{headers: Headers.headers})
  }
  performRemoteStopTransaction(body){
    return this.http.post(API_POST_REMOTE_STOP_TRANSACTION,body,{headers: Headers.headers})
  }
  performReserveNow(body){
    return this.http.post(API_POST_RESERVE_NOW,body,{headers: Headers.headers})
  }
  performReset(body){
    return this.http.post(API_POST_RESET,body,{headers: Headers.headers})
  }
  performSendLocalList(body){
    return this.http.post(API_POST_SEND_LOCAL_LIST,body,{headers: Headers.headers})
  }
  performTriggerMessage(body){
    return this.http.post(API_POST_TRIGGER_MESSAGE,body,{headers: Headers.headers})
  }
  performUnlockCharger(body){
    return this.http.post(API_POST_UNLOCK_CHARGER,body,{headers: Headers.headers})
  }
}
