<div class="header">
    <h4><span> Live Transaction</span></h4>
</div>
<!-- <div class="filter-container">
    <div class="p-formgroup-inline">
        <div class="p-field">
            <div class="pb-1"> User Id</div>
            <input [ngModelOptions]="{ standalone: true }" type="text" [(ngModel)]="userId" pInputText />
        </div>
        <div class="p-field">
            <div class="pb-1">&nbsp;</div>
            <button pButton type="button" label="Submit" (click)="getUser()"></button>
        </div>
        <div class="button-container">
        <span style="margin: 0;position: absolute;top: 56%;-ms-transform: translateY(-50%);transform: translateY(-50%);"><button pButton type="button" label="Submit" (click)="getUser()"></button></span>
        </div>
    </div>
</div> -->

<div class="table">
<p-table #dt [columns]="cols" [value]="data" [paginator]="true" [rows]="50" [autoLayout]="true">
    <ng-template pTemplate="caption">
        <div class="p-formgroup-inline">
            <div class="p-field">
                <div class="pb-1 counter total"> Total Charge Box: {{ this.totalCharger }} </div>
            </div>
            <!-- <div class="p-field">
                <div class="pb-1 counter connected"> Available : {{availableCount}} </div>
            </div> -->
            <!-- <div class="p-field">
                <div class="pb-1 counter connected"> Connected (Not charging) : {{connectedCount}} </div>
            </div> -->
            <!-- <div class="p-field">
                <div class="pb-1 counter connected"> Preparing : {{preparingCount}} </div>
            </div>
            <div class="p-field">
                <div class="pb-1 counter connected"> Charging : {{chargingCount}} </div>
            </div>
            <div class="p-field">
                <div class="pb-1 counter available"> Completed : {{completeCount}} </div>
            </div>
            <div class="p-field">
                <div class="pb-1 counter available"> Reserved : {{reservedCount}} </div>
            </div>
            <div class="p-field">
                <div class="pb-1 counter stopped"> Unavailable : {{unavailableCount}} </div>
            </div>
            <div class="p-field">
                <div class="pb-1 counter stopped"> Faulted : {{faultedCount}} </div>
            </div>     -->
        <div class="p-field">
            <input  class="form-control" type="text" style="width: 250px;" pInputText size="50" placeholder="All Filter"
            (input)="dt.filterGlobal($event.target.value, 'contains')" lazy="false" />
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns">
              <th [ngStyle]="{'width': col.width}">
                  <ng-container [ngSwitch]="col.field">
                     <ng-container *ngSwitchDefault>{{ col.header }}</ng-container>
                   </ng-container>
              </th>
        </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-columns="columns" let-rowIndex="rowIndex">
        <tr>
            <ng-container *ngFor="let col of columns" >
            <td>
                <ng-container [ngSwitch]="col.field">
                    <ng-container *ngSwitchCase="'createdTimeStamp'">
                        <span *ngIf="data.createdTimeStamp">{{ data.createdTimeStamp | date: "dd-MMM-yy" }} at {{ data.createdTimeStamp | date: "shortTime" }}</span> 
                    </ng-container>
                    <ng-container *ngSwitchCase="'payment'">
                       <span *ngIf="data.payment!= undefined">Rs. </span>{{ data.payment }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'action'">
                        <span style="padding-left: 33%;">
                            <a (click)="perfomAction(data)"><i class="fa fa-window-close fa-lg"></i></a>
                        </span> 
                     </ng-container>
                    <ng-container *ngSwitchCase="'charge'">
                        <span *ngIf="data.charge.startTimestampDT">{{data.charge.startTimestampDT | date: "yyyy-MM-dd" }} at {{ data.charge.startTimestampDT | date: "shortTime" }}<br/></span>
                        <span *ngIf="data.charge.stopTimestampDT">{{data.charge.stopTimestampDT | date: "yyyy-MM-dd" }} at {{ data.charge.stopTimestampDT | date: "shortTime" }}</span>
                     </ng-container>
                    <ng-container *ngSwitchCase="'sno'">
                           {{rowIndex +1 }}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{data[col.field]}}
                    </ng-container>
                </ng-container>
            </td>
            </ng-container>
        </tr>
    </ng-template>
</p-table>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "var(--white)" type = "timer" [fullScreen] = "true"><p style="color: var(--white)" > Loading... </p></ngx-spinner>
