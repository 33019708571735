import { Injectable } from '@angular/core';
import { Headers } from '../model/api.headers';
import { HttpClient } from '@angular/common/http';
import { API_FETCH_USER,API_UPDATE_USER,API_DELETE_USER} from '../config/index';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http : HttpClient) { }

  getAllUser(){
   return this.http.get(API_FETCH_USER, {headers: Headers.headers})
  }
  update(data){
    return this.http.post(API_UPDATE_USER,data,{headers: Headers.headers})
  }
  deleteUser(userPk){
    let body={}
    return this.http.post(API_DELETE_USER+userPk,body,{headers: Headers.headers})
  }
}
