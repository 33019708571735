import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { TransactionsService } from '../../services/transactions.service';
import {ReservationsService} from '../../services/reservations.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-past-reservation',
  templateUrl: './past-reservation.component.html',
  styleUrls: ['./past-reservation.component.css']
})
export class PastReservationComponent implements OnInit {
  constructor(private transactionService: ReservationsService,private spinner: NgxSpinnerService) { }


  cols: any[];
  data ;
  statusMap ={
    'EXPIRED':'Expired',
    'CANCELLED': 'Cancelled',
    'WAITING':'Waiting',
    'INITIATED':'Initiated',
    'INITIATION_FAILED':'Initiation Failed',
    'NOT_AVAILABLE': 'Not Availble',
    'TRANSACTION_ENDED':'Transaction Ended',
    'TRANSACTION_STARTED':'Tranaction Started',
    'WAITING_FOR_ACCEPTANCE': 'Waiting For Acceptance'
  }
  statusClass ={
    'EXPIRED':'blue-green',
    'CANCELLED': 'orange',
    'WAITING':'pink',
    // 'INITIATED':'blue',
    // 'INITIATION_FAILED':'green',
    // 'NOT_AVAILABLE': 'yellow',
    // 'TRANSACTION_ENDED':'pink',
    // 'TRANSACTION_STARTED':'dark-green',
    // 'WAITING_FOR_ACCEPTANCE': 'brown'
  }

  ngOnInit(): void {
    this.spinner.show();
    this.transactionService.getPastReservation().subscribe(res=>{
      // console.log(res)
      this.data = res['response']
      this.data.forEach(reservation => {
        reservation.vehicleNumber = reservation.reservation.vehicleNumber
        reservation.charger = reservation.charger.displayName
        reservation.user = reservation.reservation.userName
        reservation.userPhone = reservation.reservation.phoneNo
        reservation.startDatetimeDT = reservation.reservation.startDatetimeDT
        reservation.duration = this.getDuration(reservation.reservation.startDatetimeDT,reservation.reservation.expiryDatetimeDT)
        reservation.power =reservation.reservation.powerConsumption
        reservation.payment = reservation.reservation.payment
        reservation.status= reservation.reservation.status
      });
      this.spinner.hide();
    })
    this.cols = [
      { field: 'reservationRequestId', header: 'Id',width : '67px' },
      //{ field: 'createdTimeStamp', header: 'Created Time' },
      { field: 'vehicleNumber', header: 'Vehicle'},
      { field: 'charger', header: 'Charger' },
      { field: 'user', header: 'User'},
      { field: 'userPhone', header: 'Phone No.'},
      { field: 'startDatetimeDT', header: 'Schedule Time' },
      { field: 'duration', header: 'Duration' },
      { field: 'power', header: 'Power Consumption (kWh)' }, 
      { field: 'payment', header: 'Payment' },
      { field: 'status', header: 'Status' },
      // { field: 'button', header: '' }
  ];
  }
  getDuration(start,end){
    let diff = (end - start)/1000;
    diff = Math.abs(diff);
    if (diff < 60) {
      return  diff.toFixed() + ' sec';
    }
    else if (diff >= 60 && diff < 3600) {
      let minute = Math.floor(diff / 60);
      return  minute + ' min';
    }
    else {
      let minute = Math.floor(diff/60);
      let hour = Math.floor(minute/60);
      minute = Math.floor(minute - hour*60);
      return  hour + ' hr ' + minute + ' min' ;
    }
  }

}
