import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { UIChart } from 'primeng/chart';
import { ChargePointService } from '../../services/charge-point.service';
import { ConfigService } from '../../services/config.service';
import { DataSharingService } from '../../services/data-sharing.service';
import { HomeService } from '../../services/home.service';

@Component({
  selector: 'charger-graph',
  templateUrl: './charger-graph.component.html',
  styleUrls: ['./charger-graph.component.css']
})
export class ChargerGraphComponent implements OnInit {

  @Input()
  chargerBoxId
  @ViewChild("linechart") chart: UIChart;
  accessTypes: { label: string; value: string; }[];
  accessType: string;
  basicData;
  basicOptions;
  selectedDate =moment().startOf('d').toDate()
  selectedDates = [moment().startOf('d').subtract(7,'d').toDate(), moment().startOf('d').toDate()];
  chargerList=[];
  charger: any;
  hubs: { label: string; value: string; }[];
  hub: string;
  constructor(private homeSerivce: HomeService,private chargePointService: ChargePointService,private dataSharingService: DataSharingService) { }

  ngOnInit(): void {
    this.chargePointService.getChargePoints().subscribe(res =>{
      this.chargerList.push({label: "All", value: "ALL"})
      res['response']['cpList'].forEach(charger => {
        if(charger.chargerDisplayName)
        this.chargerList.push({label: charger.chargerDisplayName, value: charger.chargerDisplayName})
        this.charger =this.chargerBoxId
       });
       
    })
    this.accessTypes = [{label: 'Daily-wise', value: 'DAILY'},
                        {label: 'Hourly', value: 'HOURLY'}]
    this.accessType = 'HOURLY'
    
    this.hubs= [{label: 'AIHP', value: 'AIHP'},
    {label: 'ANSAL', value: 'ANSAL'},
    {label: 'SPAZE', value: 'SPAZE'},
    {label: 'SEC-52', value: 'SEC-52'},
    {label: 'KAPASHERA', value: 'KAPASHERA'}
    ]
    this.hub = 'AIHP'
    this.basicData = {
          labels: [],
          datasets: [
                      {
                        label: 'Utilization %',
                        data: [],
                        fill: false,
                        borderColor: '#42A5F5',
                        lineTension: 0,
                      }
                    ]
          }
    this.basicOptions = {

                      legend: {
                         display:false,
                          labels: {
                              fontColor: '#6d809a',
                              fontSize: 15,
                          }
                      },
                      scales: {
                          xAxes: [{
                              ticks: {
                                  fontColor: '#6d809a',
                                  fontSize: 15,
                              },
                              display:true,
                              scaleLabel: {
                                display: true,
                                labelString: 'Time',
                                fontSize: 15,
                                fontColor: '#6d809a'},
                              gridLines: {
                                  color: 'rgba(255,255,255,0.2)'
                              }
                          }],
                          yAxes: [{
                              ticks: {
                                  fontColor: '#6d809a',
                                  fontSize: 15,
                              },
                              display:true,
                              scaleLabel: {
                                display: true,
                                fontColor: '#6d809a',
                                labelString: '% Utilization',
                                fontSize: 15,},
                              gridLines: {
                                  color: 'rgba(255,255,255,0.2)'
                              }
                          }]
                      }
                  };
    this.getGraphData()
  }
  getTime(timestamp){
    return new Date(timestamp).toLocaleTimeString([],{ hour: '2-digit', minute: '2-digit' })
  }
  getDate(timestamp){
    return new Date(timestamp).toLocaleString('en-us', { day: "2-digit",month: "short" }) ;
  }
  getGraphData(){
    this.selectedDates[1]= new Date(this.selectedDates[0].getTime() + 604800000);
    let from,to
    let charger = this.charger ? this.charger : this.chargerBoxId
    if(this.charger=="ALL"){
      from = this.accessType == 'HOURLY' ? this.selectedDate.getTime() :  this.selectedDates[0].getTime()
      to = this.accessType == 'HOURLY' ? this.selectedDate.getTime() + 86400000 : this.selectedDates[1].getTime()// 1 day

      this.homeSerivce.getAllUtilization(this.accessType,from,to,this.hub).subscribe(res=>{
        if(res['status']=='SUCCESS'){
          this.basicData['labels']=[]
          this.basicData['datasets']=[]
          let data=[] 
         res['response'].forEach(
         res => {
           this.basicData['labels'].push(this.accessType == 'HOURLY' ? this.getTime(res.toTimestamp): this.getDate(res.toTimestamp))
           data.push(Number(res.utilizationPercent).toFixed(2))
          })
          this.basicData['datasets'].push({ label: charger, data : data, fill: false, borderColor: '#42A5F5',lineTension: 0 })
        this.chart.refresh();
        }
      })
      return
    }

    if(this.accessType == 'HOURLY')
    {
      from = this.selectedDate.getTime()
      to = this.selectedDate.getTime() + 86400000 // 1 day
    } else{
      from = this.selectedDates[0].getTime()
      to =this.selectedDates[1].getTime()
    }
    this.homeSerivce.getUtilization(this.accessType,from,to,charger).subscribe(res=>{
      if(res['status']=='SUCCESS')
      {  this.basicData['labels']=[]
         this.basicData['datasets']=[]
         let data=[] 
        res['response'].forEach(
        res => {
          this.basicData['labels'].push(this.accessType == 'HOURLY' ? this.getTime(res.toTimestamp): this.getDate(res.toTimestamp))
          data.push(Number(res.utilizationPercent).toFixed(2))
         })
         this.basicData['datasets'].push({ label: charger, data : data, fill: false, borderColor: '#42A5F5',lineTension: 0 })
       this.chart.refresh();
    }
    })
  }
}
