import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {

  constructor(private homeSerivce :  HomeService) { }

  public logs
  ngOnInit(): void {
    this.homeSerivce.getLogs().subscribe(res=>{
      //console.log(res)
    },error => {
       this.logs = error.error.text
      // console.log(error.error.text)
    })
  }

}
