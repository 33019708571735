import { Component, OnInit, ViewChild } from '@angular/core';
import { HomeService } from '../../services/home.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { DataSharingService } from '../../services/data-sharing.service';
import { UIChart } from 'primeng/chart';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  accessTypes: { label: string; value: string; }[];
  accessType: string;
  @ViewChild("linechart") chart: UIChart;
  public cols
  constructor(private homeSerivce: HomeService,private spinner: NgxSpinnerService,private dataSharingService: DataSharingService) { 
    this.cols = [
      { field: 'hub', header: 'Hub' },
      { field: 'totalMappedChargers', header: 'Total Mapped Chargers' },
      { field: 'totalActiveChargers', header: 'Total Active Chargers'},
      { field: 'totalConnectedChargers', header: 'Total Connected Chargers' },
      { field: 'totalIdle', header: 'Total Idle Chargers'},
      { field: 'totalBreakDownCharger', header: 'Total Breakdown Chargers'},
  ];
  }
  basicData: any;
  basicOptions: any;
  utilizationPer 
  liveSummaryArray = []
  hubMap ={};
  listofMap = {}
  selectedDate =moment().startOf('d').toDate()
  selectedDates = [moment().startOf('d').subtract(7,'d').toDate(), moment().startOf('d').toDate()];
  selectedDatesByDaily = moment().startOf('d').toDate();
  liveSummary
  public homeRes =
   {heartbeatEarlier: 0,
  heartbeatToday: 0,
  heartbeatYesterday: 0,
  numChargeBoxes: 0,
  numOcpp12JChargeBoxes: 0,
  numOcpp15JChargeBoxes: 0,
  numOcpp16JChargeBoxes: 0,
  numOcppTags: 0,
  numReservations: 0,
  numTransactions: 0,
  numUsers: 0,
  statusCountMap:{
                  Available: 0,
                  Charging: 0 ,
                  Faulted: 0,
                  Finishing: 0,
                  Preparing:0,
                  Unavailable: 0,
                  Reserved: 0,
                  Connected: 0,
                }
}


  ngOnInit(): void {
    this.spinner.show()
    
    this.homeSerivce.getHomeData().subscribe(res=>{
      this.homeRes = res['response']
      this.spinner.hide()
    })
    this.getSummary()
    this.accessTypes = [{label: 'Daily-wise', value: 'ALL_DAILY'},
                 {label: 'Hourly', value: 'ALL_HOURLY'}]
    this.accessType = 'ALL_HOURLY'
    
    this.basicData = {
      labels: [],
      datasets: [
          {
              label: 'Utilization %',
              data: [],
              fill: false,
              lineTension: 0,
              borderColor: '#42A5F5'
          }
      ]
  }

  this.basicOptions = {
    legend: {
        labels: {
            fontColor: '#6d809a',
            fontSize: 15,
        }
    },
    scales: {
        xAxes: [{
            ticks: {
                fontColor: '#6d809a',
                fontSize: 15,
            },
            display:true,
            scaleLabel: {
              display: true,
              labelString: 'Time',
              fontSize: 15,
              fontColor: '#6d809a'},
            gridLines: {
                color: 'rgba(255,255,255,0.2)'
            }
        }],
        yAxes: [{
            ticks: {
                fontColor: '#6d809a',
                fontSize: 15,
            },
            display:true,
            scaleLabel: {
              display: true,
              fontColor: '#6d809a',
              labelString: '% Utilization',
              fontSize: 15,},
            gridLines: {
                color: 'rgba(255,255,255,0.2)'
            }
        }]
    }
};
this.onSelect();
this.getTodayUtilization();

  }
  onSelect(){
    this.selectedDates[1]= new Date(this.selectedDates[0].getTime() + 604800000); // 7 days
    let from,to
    if(this.accessType == 'ALL_HOURLY')
    {
      from = this.selectedDate.getTime()
      to = this.selectedDate.getTime() + 86400000
    } else{
      from = this.selectedDates[0].getTime()
      to =this.selectedDates[1].getTime()
    }
    if(this.dataSharingService.hub == 0)
    {this.homeSerivce.getUtilization(this.accessType,from,to,'ALL_CHARGERS').subscribe(res=>{
      if(res['status']=='SUCCESS')
      {  this.basicData['labels']=[]
         this.basicData['datasets'][0]['data']=[]
        res['response'].forEach(
        res => {
          this.basicData['labels'].push(this.accessType == 'ALL_HOURLY' ? this.getTime(res.toTimestamp): this.getDate(res.toTimestamp))
          this.basicData['datasets'][0]['data'].push(Number(res.utilizationPercent).toFixed(2))
        }
      )
       this.chart.refresh();
    }
    }) 
    }
    else 
    { let hubName=''
      this.dataSharingService.hubList.forEach(hub=>{
      if(hub.value == this.dataSharingService.hub)
         hubName = hub.label
    })
      this.homeSerivce.getAllUtilization(this.accessType == 'ALL_HOURLY' ? 'HOURLY' : 'DAILY',from,to,hubName).subscribe(res=>{
      if(res['status']=='SUCCESS')
      {  this.basicData['labels']=[]
         this.basicData['datasets'][0]['data']=[]
        res['response'].forEach(
        res => {
          this.basicData['labels'].push(this.accessType == 'ALL_HOURLY' ? this.getTime(res.toTimestamp): this.getDate(res.toTimestamp))
          this.basicData['datasets'][0]['data'].push(Number(res.utilizationPercent).toFixed(2))
        }
      )
       this.chart.refresh();
    }
    })
   }

  } 

  getTime(timestamp){
    return new Date(timestamp).toLocaleTimeString([],{ hour: '2-digit', minute: '2-digit' })
  }
  getDate(timestamp){
    return new Date(timestamp).toLocaleString('en-us', { day: "2-digit",month: "short" }) ;
  }
  getTodayUtilization(){
    let from =moment().startOf('d').toDate().getTime()
    let to = moment().startOf('d').add(1,'d').toDate().getTime()
    if(this.dataSharingService.hub == 0){
    this.homeSerivce.getUtilization('ALL_HOURLY',from,to,'ALL_CHARGERS').subscribe(res=>{
      if(res['status']=='SUCCESS')
      { let numOfdataSet=0;
        let utilization = 0
         res['response'].forEach(
        res => {
          utilization =utilization + Number(res.utilizationPercent)
          numOfdataSet++;
        }
      )
      this.utilizationPer = Number(utilization / numOfdataSet).toFixed(2)
    }
    })} else {
      let hubName=''
      this.dataSharingService.hubList.forEach(hub=>{
      if(hub.value == this.dataSharingService.hub)
         hubName = hub.label
    })
    this.homeSerivce.getAllUtilization('HOURLY',from,to,hubName).subscribe(res=>{
      if(res['status']=='SUCCESS')
      { let numOfdataSet=0;
        let utilization = 0
         res['response'].forEach(
        res => {
          utilization =utilization + Number(res.utilizationPercent)
          numOfdataSet++;
        }
      )
      this.utilizationPer = Number(utilization / numOfdataSet).toFixed(2)
    }
    })
    }
  }
   getSummary(){
    this.spinner.show()
    this.dataSharingService.getHubList().subscribe(res=> {
      let hubId = res['response'];
      hubId.forEach(element=>{
        this.hubMap[element['id']] = element['name']
        this.listofMap = this.hubMap
      })
    });
    this.liveSummaryArray = []
    this.homeSerivce.getLiveSummary(this.selectedDatesByDaily.getTime()).subscribe(res=> {
      this.liveSummary = res['response']
      this.spinner.hide()
      var result = Object.keys(this.liveSummary).map((key) => 
      [Number(key), this.liveSummary[key]]);
      for(var data = 0; data < result.length; data++){
        let liveSummaryObject = {
          "hub": "",
          "totalMappedChargers": "",
          "totalConnectedChargers": "",
          "totalActiveChargers": "",
          "totalBreakDownCharger": "",
          "totalIdle" : ""
        }
        liveSummaryObject.hub = this.listofMap[result[data][0]]?this.listofMap[result[data][0]]:"-",
        liveSummaryObject.totalConnectedChargers = result[data][1]['Charging'] + result[data][1]['Preparing']?result[data][1]['Charging'] + result[data][1]['Preparing']:0,
        liveSummaryObject.totalBreakDownCharger = result[data][1]['Faulted'] + result[data][1]['Unavailable']?result[data][1]['Faulted'] + result[data][1]['Unavailable']:0,
        liveSummaryObject.totalIdle = result[data][1]['Finishing']?result[data][1]['Finishing']:0
        liveSummaryObject.totalActiveChargers = liveSummaryObject.totalConnectedChargers + liveSummaryObject.totalIdle
        liveSummaryObject.totalMappedChargers = liveSummaryObject.totalActiveChargers + liveSummaryObject.totalBreakDownCharger,
        this.liveSummaryArray.push(liveSummaryObject)
      }
    })
  }
}
