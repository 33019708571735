
<div class="header">
    <h4><span> Ocpp Settings</span></h4>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-6"> <div style="width: 100%;text-align: right;color: var(--white);">Heartbeat Interval:</div></label>
    <div class="p-col">
        <input id="firstname3" [(ngModel)]="settingDetails.heartbeat" type="text" pInputText> 
    </div>
</div>
<div class="p-field p-grid ">
    <label  class="p-col-6"> <div style="width: 100%;text-align: right;color: var(--white);"><em>The time interval in <strong>minutes</strong> for how often a charge point
        should request the current time from SteVe.</em>	</div></label>
    <div class="p-col">
    </div>
</div>
<div class="p-field p-grid">
    <label for="expiration" class="p-col-6" ><div style="width: 100%;text-align: right;color: var(--white);">Expiration:</div></label>
    <div class="p-col">
        <input id="expiration" type="text" [(ngModel)]="settingDetails.expiration" pInputText> 
    </div>
</div>
<div class="p-field p-grid ">
    <label class="p-col-6"> <div style="width: 100%;text-align: right;color: var(--white);"><em>The amount of time in <strong>hours</strong>  for how long a charge point
        should store the authorization info of an idTag in its local var(--white) list.</em></div></label>
    <div class="p-col">
    </div>
</div>
<div class="header">
    <h4><span> Mail Notification Settings</span></h4>
</div>
<div class="p-field p-grid">
    <label for="enabled " class="p-col-6" ><div style="width: 100%;text-align: right;color: var(--white);">Enable Notifications?:</div></label>
    <div class="p-col">
        <p-checkbox name="enabled" [binary]="true" [(ngModel)]="settingDetails.enabled"></p-checkbox>
    </div>
</div>
<div class="p-field p-grid">
    <label for="protocol" class="p-col-6" ><div style="width: 100%;text-align: right;color: var(--white);">Protocol:</div></label>
    <div class="p-col">
        <input id="protocol" type="text" [(ngModel)]="settingDetails.protocol" pInputText> 
    </div>
</div>
<div class="p-field p-grid">
    <label for="host" class="p-col-6" ><div style="width: 100%;text-align: right;color: var(--white);">Host:</div></label>
    <div class="p-col">
        <input id="host" type="text" [(ngModel)]="settingDetails.host" pInputText> 
    </div>
</div>
<div class="p-field p-grid">
    <label for="port" class="p-col-6" ><div style="width: 100%;text-align: right;color: var(--white);">Port:</div></label>
    <div class="p-col">
        <input id="port" type="text" [(ngModel)]="settingDetails.port" pInputText> 
    </div>
</div>
<div class="p-field p-grid">
    <label for="from" class="p-col-6" ><div style="width: 100%;text-align: right;color: var(--white);">from:</div></label>
    <div class="p-col">
        <input id="from" type="text" [(ngModel)]="settingDetails.from" pInputText> 
    </div>
</div>
<div class="p-field p-grid">
    <label for="username" class="p-col-6" ><div style="width: 100%;text-align: right;color: var(--white);">User name:</div></label>
    <div class="p-col">
        <input id="username" type="text" [(ngModel)]="settingDetails.username" pInputText> 
    </div>
</div>
<div class="p-field p-grid">
    <label for="password" class="p-col-6" ><div style="width: 100%;text-align: right;color: var(--white);">Password:</div></label>
    <div class="p-col">
        <input id="password" type="text" [(ngModel)]="settingDetails.password" pInputText> 
    </div>
</div>
<div class="p-field p-grid">
    <label for="recipients" class="p-col-6" ><div style="width: 100%;text-align: right;color: var(--white);">Recipients:</div></label>
    <div class="p-col">
        <textarea [rows]="4" [cols]="30" pInputTextarea autoResize="autoResize" [(ngModel)]="settingDetails.recipients"></textarea>
    </div>
</div>

<div class="p-field p-grid">
    <label for="enabled " class="p-col-6" ><div style="width: 100%;text-align: right;color: var(--white);">Notify when...</div></label>
    <div class="p-col">
        <div class="p-field-checkbox">
            <p-checkbox binary="true"  val="OcppStationBooted" [(ngModel)]="OcppStationBooted"></p-checkbox>
            <label for="ny">a charging station sends a boot notification (Note: This activates notifications about failed connection attempts for unregistered JSON stations, as well)</label>
        </div>
        <div class="p-field-checkbox">
            <p-checkbox binary="true" val="OcppStationStatusFailure"  [(ngModel)]="OcppStationStatusFailure"></p-checkbox>
            <label>a connector gets faulted</label>
        </div>
        <div class="p-field-checkbox">
            <p-checkbox binary="true" val="OcppStationWebSocketConnected" [(ngModel)]="OcppStationWebSocketConnected"></p-checkbox>
            <label>a JSON charging station connects</label>
        </div>
        <div class="p-field-checkbox">
        <p-checkbox binary="true" val="OcppStationWebSocketDisconnected" [(ngModel)]="OcppStationWebSocketDisconnected"></p-checkbox>
        <label>a JSON charging station disconnects</label>
        </div>
    </div>
</div>
<div class="p-field p-grid">
    <label for="recipients" class="p-col-6" ><div style="width: 100%;text-align: right;color: var(--white);"></div></label>
    <div class="p-col">
        <button type="submit" class="btn btn-primary submit" (click)="updateForm()">Change</button>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "var(--white)" type = "timer" [fullScreen] = "true"><p style="color: var(--white)" > Loading... </p></ngx-spinner>
