<div class="row" style="height: 100%; margin: 0px;">

    <div class="col-3" style="border-right: 2px solid var(--grey-20);padding: 0px;">

        <div class="menu">    
            <ul>
                <li *ngIf="true">
                  <a routerLink="/configv1.6/changeAvailability" routerLinkActive="menu-active" ><span>Change Availability</span></a>
                </li>
                <li *ngIf="true">
                  <a routerLink="/configv1.6/changeConfiguration" routerLinkActive="menu-active" ><span>Change Configuration</span></a>
                </li>
                <li *ngIf="true">
                  <a routerLink="/configv1.6/clearCache" routerLinkActive="menu-active" >
                    <span >Clear Cache</span></a>
                </li>
                <li *ngIf="true">
                    <a routerLink="/configv1.6/remoteStartTransaction" routerLinkActive="menu-active" >
                      <span >Remote Start Transaction</span></a>
                  </li>
                  <li *ngIf="true">
                    <a routerLink="/configv1.6/remoteStopTransaction" routerLinkActive="menu-active" >
                      <span >Remote Stop Transaction</span></a>
                  </li>
                  <li *ngIf="true">
                    <a routerLink="/configv1.6/reset" routerLinkActive="menu-active" >
                      <span >Reset</span></a>
                  </li>
                  <li *ngIf="true">
                    <a routerLink="/configv1.6/unlockConnector" routerLinkActive="menu-active" >
                      <span >Unlock Connector</span></a>
                  </li>

                <li style="height: 2px; background: var(--grey-20);">
        
                </li>
                <!-- <li *ngIf="true">
                  <a routerLink="/configv1.6/reserveNow" routerLinkActive="menu-active" > <span
                      >Reserve Now</span></a>
                </li> -->
                <li *ngIf="true">
                  <a routerLink="/configv1.6/cancelReservation" routerLinkActive="menu-active" > <span
                      >Cancel Reservation</span></a>
                </li>
                <li *ngIf="true">
                  <a routerLink="/configv1.6/getConfiguration" routerLinkActive="menu-active" > <span
                      >Get Configuration</span></a>
                </li>
                <li *ngIf="true">
                  <a routerLink="/configv1.6/getLocalListVersion" routerLinkActive="menu-active" > <span
                      >Get Local List Version</span></a>
                </li>
                <li *ngIf="true">
                    <a routerLink="/configv1.6/sendLocalList" routerLinkActive="menu-active" > <span
                        >Send Local List</span></a>
                  </li>
                <li style="height: 2px; background: var(--grey-20)">
        
                </li>
          
                <li *ngIf="true">
                  <a routerLink="/configv1.6/triggerMessage"  routerLinkActive="menu-active" ><span >Trigger Message</span></a>
                </li>
        
              </ul>
        
        
        </div>



    </div>

    <div class="col-9" style="padding: 0px;">
        <router-outlet></router-outlet>
    </div>

</div>
