import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';

@Component({
  selector: 'app-unlock-connector',
  templateUrl: './unlock-connector.component.html',
  styleUrls: ['./unlock-connector.component.css']
})
export class UnlockConnectorComponent implements OnInit {


  availabilityTypes: any[];

  constructor(private dataSharingService :DataSharingService,private configService: ConfigService ,private router : Router) { }
  public charger ;
  public chargerList;
  public existingReservationList;
  public connectorId;
  public userIds;
  public userId

  ngOnInit(): void {
    if(this.dataSharingService.chargerPoints.length == 0)
    this.configService.getChargers().subscribe(res =>{
      console.log(res['response']['cpList'])
      this.dataSharingService.chargerPoints=[]
      res['response']['cpList'].forEach(charger => {
        this.dataSharingService.chargerPoints.push({label: charger.chargeBoxId, value: charger.chargeBoxId})
       });
      this.chargerList = this.dataSharingService.chargerPoints;
    })
    else
    this.chargerList = this.dataSharingService.chargerPoints;
  this.existingReservationList =[]
  this.userIds = [{label: 'USER-1', value: 'INOPERATIVE'},
                  {label: 'USER-2', value: 'OPERATIVE'}]
  }
  performAction(){
  let body ={
    "chargePointSelectList": [{ "chargeBoxId" : this.charger,"ocppTransport": "JSON"}],
    "connectorId": this.connectorId
  }
  this.configService.performUnlockCharger(body).subscribe(res=>{
    // console.log(res)
    this.router.navigateByUrl('/tasks/detail/'+res['response']);
  },error =>{
    // console.log(error)
  })  
  }
}
