import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-remote-start-transaction',
  templateUrl: './remote-start-transaction.component.html',
  styleUrls: ['./remote-start-transaction.component.css']
})
export class RemoteStartTransactionComponent implements OnInit {

  availabilityTypes: any[];

  constructor(private dataSharingService :DataSharingService,private configService: ConfigService, private userService: UsersService,private router : Router) { }
  public charger ;
  public chargerList;
  public existingReservationList;
  public connectorId;
  public userIds =[];
  public userId
  public vehicleNumber

  ngOnInit(): void {
    if(this.dataSharingService.chargerPoints.length == 0)
    this.configService.getChargers().subscribe(res =>{
      // console.log(res['response']['cpList'])
      this.dataSharingService.chargerPoints=[]
      res['response']['cpList'].forEach(charger => {
        this.dataSharingService.chargerPoints.push({label: charger.chargeBoxId, value: charger.chargeBoxId})
       });
      this.chargerList = this.dataSharingService.chargerPoints;
      this.charger = this.chargerList[0] ? this.chargerList[0].value : null;
    })
    else
    this.chargerList = this.dataSharingService.chargerPoints;
    this.charger = this.chargerList[0] ? this.chargerList[0].value : null;
    this.userService.getAllUser().subscribe(res=>{
      let response = res['response']
      // console.log(response)
      response.forEach(user => {
        this.userIds.push({ label : user.name+"_"+user.userPk , value: user.userPk})
      });
    })
  this.existingReservationList =[]
  }
  
  performAction(){
    let body ={
      "chargePointSelectList" : [{ "chargeBoxId" : this.charger, "ocppTransport": "JSON"}],
      "connectorId": this.connectorId,
      "userId": this.userId,
      "vehicleNumber": this.vehicleNumber
    }
    // {
    //   "chargePointSelectList": [
    //     {
    //       "chargeBoxId": "string",
    //       "endpointAddress": "string",
    //       "endpointAddressSet": true,
    //       "ocppTransport": "SOAP",
    //       "soap": true
    //     }
    //   ],
    //   "connectorId": 0,
    //   "connectorPk": 0,
    //   "fleetId": 0,
    //   "idTag": "string",
    //   "paymentMode": "PAYTM_CREDIT_CARD",
    //   "reservationId": 0,
    //   "userId": 0,
    //   "vehicleNumber": "string"
    // }
    // console.log(body)
    this.configService.performRemoteStartTransaction(body).subscribe(res=>{
      // console.log(res)
      this.router.navigateByUrl('/tasks/detail/'+res['response']);
    },error=>{
      // console.log(error)
    })
  }
}
