import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeService } from 'src/app/services/home.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-live-transactions',
  templateUrl: './live-transactions.component.html',
  styleUrls: ['./live-transactions.component.css']
})
export class LiveTransactionsComponent implements OnInit {
  public timerId = null;

  constructor(private transactionService: TransactionsService,private spinner: NgxSpinnerService,private homeService: HomeService) { }
  public cols
  public data
  totalCharger = 0
  availableCount=0
  connectedCount=0
  chargingCount=0
  completeCount=0
  unavailableCount=0
  faultedCount=0
  reservedCount=0
  preparingCount=0

  ngOnInit(): void {
  this.getLiveTransaction();
  this.cols = [
    { field: 'transactionId', header: 'transaction Id' },
    { field: 'createdTimeStamp', header: 'Created Time' },
    { field: 'vehicleNumber', header: 'Vehicle'},
    { field: 'charger', header: 'Charger' },
    { field: 'startSoc', header: 'Initial SOC'},
    { field: 'endSoc', header: 'Current SOC'},
    { field: 'chargingTime', header: 'Charging Time' },
    { field: 'unit', header: 'Unit Consumption (kWh)' },
    { field: 'payment', header: 'Payment' },
    // { field: 'charge', header: 'Charging History' }, 
    { field: 'action', header: 'Action' }, 
    // { field: 'payment', header: 'Details' }
    // { field: 'button', header: '' }
];
this.homeService.getHomeData().subscribe(res=>{
  if(res['status']=='SUCCESS')
   {
     if(res['response']['statusCountMap'])
    {
      this.availableCount = res['response']['statusCountMap']['Available'] ? res['response']['statusCountMap']['Available'] : 0;
      this.connectedCount = res['response']['statusCountMap']['Connected'] ? res['response']['statusCountMap']['Connected']: 0;
      this.preparingCount = res['response']['statusCountMap']['Preparing'] ? res['response']['statusCountMap']['Preparing']: 0;
      this.chargingCount = res['response']['statusCountMap']['Charging'] ? res['response']['statusCountMap']['Charging']: 0; 
      this.completeCount =res['response']['statusCountMap']['Finishing'] ? res['response']['statusCountMap']['Finishing']: 0; 
      this.unavailableCount = res['response']['statusCountMap']['Unavailable'] ? res['response']['statusCountMap']['Unavailable']: 0; 
      this.faultedCount = res['response']['statusCountMap']['Faulted'] ? res['response']['statusCountMap']['Faulted']: 0; 
      this.reservedCount = res['response']['statusCountMap']['Reserved'] ? res['response']['statusCountMap']['Reserved']: 0; 
      this.totalCharger = res['response']['numChargeBoxes']
    }
   }
})
}
ngOnDestroy() {
  clearInterval(this.timerId);
}
getDuration(start,end){
  let diff = (end - start)/1000;
  diff = Math.abs(diff);
  if (diff < 60) {
    return  diff.toFixed() + ' sec';
  }
  else if (diff >= 60 && diff < 3600) {
    let minute = Math.floor(diff / 60);
    return  minute + ' min';
  }
  else {
    let minute = Math.floor(diff/60);
    let hour = Math.floor(minute/60);
    minute = Math.floor(minute - hour*60);
    return  hour + ' hr ' + minute + ' min' ;
  }
}

  perfomAction(data){
    // console.log(data)
    Swal.fire({
      title: 'Are you sure you want to stop the Transaction id: '+data.transactionId+'?',
      // text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Stop it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.transactionService.stopTransaction(data.transactionId).subscribe(res=>{
        // console.log(res)
        Swal.fire(
          'Stopped!',
          'Transaction Id: '+data.transactionId+' has been stopped.',
          'success'
        )
        this.getLiveTransaction()
      },error =>{
        Swal.fire(
          'Something went wrong!',
          error.error.errorMessage,
          'error'
        )
      })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          '',
          'error'
        )
      }
    })

  }
  getLiveTransaction(){
    this.transactionService.getLiveTransaction().subscribe(res=>{
      this.data = res['response']
      this.data.forEach(transaction => {
        transaction.transactionId =transaction.transaction.id
        transaction.createdTimeStamp = transaction.transaction.createdTimestamp
        transaction.vehicleNumber = transaction.vehicle.vehicleNumber
        transaction.charger = transaction.charger.displayName
        transaction.startSoc = transaction.transaction.startSoc== '-' ?  'NA' : Math.abs(transaction.transaction.startSoc)+" %"
        transaction.endSoc = transaction.transaction.endSoc=='-' ? 'NA' : Math.abs(transaction.transaction.endSoc)+" %"
        transaction.chargingTime = this.getDuration(transaction.transaction.startTimestampDT,new Date().getTime())
        transaction.unit = Number(transaction.status.units).toFixed(2)
        transaction.charge = { startTimestampDT : transaction.transaction.startTimestampDT , stopTimestampDT:  transaction.transaction.stopTimestampDT }
        transaction.payment = Number(transaction.status.chargingCost).toFixed(2)
    })
    if (!this.timerId) {
      this.timerId = setTimeout(() => {
        this.getLiveTransaction();
        this.timerId = null;
      }, 20 * 1000);
    }
  });
  }

}