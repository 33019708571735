<div class="header">
    <h4><span> Charge Point Overview </span></h4>
</div>
<div class="filter-container">
    <div class="p-formgroup-inline">
        <div class="p-field">
            <div class="pb-1"> Charge Box Id</div>
            <input [ngModelOptions]="{ standalone: true }" type="text" [(ngModel)]="chargeBoxId" pInputText />
        </div>
        <div class="p-field">
            <div class="pb-1"> Description</div>
            <input [ngModelOptions]="{ standalone: true }" type="text" [(ngModel)]="description" pInputText />
        </div>
        <div class="p-field">
            <div class="pb-1">OCPP Version</div>
            <p-dropdown [options]="OcppVersions" [(ngModel)]="selectedOcppVersion"></p-dropdown>
        </div>
        <div class="p-field">
            <div class="pb-1">Heartbeat Period:</div>
            <p-dropdown [options]="HeartBeatPeriods" [(ngModel)]="selectedHeartBeatPeriod"></p-dropdown>
        </div>
        <div class="p-field">
            <div class="pb-1">&nbsp;</div>
            <button pButton type="button" label="Submit" (click)="getCharger()"></button>
        </div>
        <!-- <div class="button-container">
        <span style="margin: 0;position: absolute;top: 56%;-ms-transform: translateY(-50%);transform: translateY(-50%);"><button pButton type="button" label="Submit" (click)="getCharger()"></button></span>
        </div> -->
    </div>
</div>

<div class="table">
<p-table #dt [columns]="cols" [value]="data" [paginator]="true" [rows]="50" [autoLayout]="true">
    <ng-template pTemplate="caption">
        <div class="p-formgroup-inline">
        <div class="p-field">
            <div class="pb-1 counter total"> Total Charge box: {{ this.totalCharger }} </div>
        </div>
        <!-- <div class="p-field">
            <div class="pb-1 counter connected"> Available : {{availableCount}} </div>
        </div> -->
        <!-- <div class="p-field">
            <div class="pb-1 counter connected"> Connected (Not charging) : {{connectedCount}} </div>
        </div> -->
        <!-- <div class="p-field">
            <div class="pb-1 counter connected"> Preparing : {{preparingCount}} </div>
        </div>
        <div class="p-field">
            <div class="pb-1 counter connected"> Charging : {{chargingCount}} </div>
        </div>
        <div class="p-field">
            <div class="pb-1 counter available"> Completed : {{completeCount}} </div>
        </div>
        <div class="p-field">
            <div class="pb-1 counter available"> Reserved : {{reservedCount}} </div>
        </div>
        <div class="p-field">
            <div class="pb-1 counter stopped"> Unavailable : {{unavailableCount}} </div>
        </div>
        <div class="p-field">
            <div class="pb-1 counter stopped"> Faulted : {{faultedCount}} </div>
        </div> -->

        <div class="p-field">
            <input  class="form-control" type="text" style="width: 250px;" pInputText size="50" placeholder="All Filter"
            (input)="dt.filterGlobal($event.target.value, 'contains')" lazy="false" />
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns">
              <th [ngStyle]="{'width': col.width}">
                  <ng-container [ngSwitch]="col.field">
                    <ng-container *ngSwitchCase="'deleteUser'">
                        <button class="btn btn-success" style="height: 33px;" (click)="openAddNew()">Add New</button>
                     </ng-container>
                     <ng-container *ngSwitchDefault>{{ col.header }}</ng-container>
                   </ng-container>
              </th>
        </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-columns="columns" let-rowIndex="rowIndex">
        <tr>
            <ng-container *ngFor="let col of columns" >
            <td>
                <ng-container [ngSwitch]="col.field">
                    <ng-container *ngSwitchCase="'deleteUser'" style="text-align: center;">
                      <span style="padding-left: 33%;">
                        <!-- <button class="btn btn-danger" (click)="deleteChargeBox(data.chargeBoxPk)">x</button> -->
                        <a (click)="deleteChargeBox(data)" style="cursor: pointer;"><i class="fa fa-window-close fa-lg"></i></a>
                        <a (click)="viewGraph(data)" style="cursor: pointer;"><i style="padding-left: 13px;" class="fa fa-chart-line fa-lg"></i></a>
                    </span> 
                    </ng-container>
                    <ng-container *ngSwitchCase="'sno'">
                           {{rowIndex +1 }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'lastHeartBeat'">
                      <span *ngIf="data.lastHeartBeat">{{ data.lastHeartBeat | date: "dd-MMM-yy" }} at {{ data.lastHeartBeat | date: "shortTime" }} </span> 
                    </ng-container>
                <ng-container *ngSwitchCase="'chargerDisplayName'">
                    <a style="text-decoration: none;" [routerLink]= "['/charger-details', data.chargeBoxPk]"> <span> {{data.chargerDisplayName}} </span>  </a>
                </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{data[col.field]}}
                      </ng-container>
                </ng-container>
            </td>
            </ng-container>
        </tr>
    </ng-template>
</p-table>
</div>

<p-dialog [(visible)]="showAddNew" [modal]="true" [responsive]="true"  [minY]="70" [maximizable]="false" [baseZIndex]="1" [style]="{
    'z-index': '999'
  }" >

    <ng-template pTemplate="header">
        Add New Charge
    </ng-template>  
    <h5 style="font-weight: 700; padding-left: 20px;">OCPP</h5>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">ChargeBox ID:</div></label>
        <div class="p-col">
            <input id="name" type="text" [(ngModel)]="chargePointForm.chargeBoxId" pInputText> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Tenant Id:</div></label>
        <div class="p-col">
            <input id="tenantId" type="number" [(ngModel)]="chargePointForm.tenantId" pInputText> 
        </div>
    </div>
    <h5 style="font-weight: 700; padding-left: 20px;">Address</h5>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Street:</div></label>
        <div class="p-col">
            <input id="email" type="text" [(ngModel)]="chargePointForm.address.street" pInputText> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">House Number:</div></label>
        <div class="p-col">
            <input id="phone" type="text" [(ngModel)]="chargePointForm.address.houseNumber" pInputText> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Zip code:</div></label>
        <div class="p-col">
            <input id="sex" type="text" [(ngModel)]="chargePointForm.address.zipCode" pInputText> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">City:</div></label>
        <div class="p-col">
            <input id="additionalNote" type="text" [(ngModel)]="chargePointForm.address.city" pInputText> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Country:</div></label>
        <div class="p-col">
            <p-dropdown [options]="countryList" [(ngModel)]="selectedCountry"></p-dropdown>
        </div>
    </div>
    <h5 style="font-weight: 700; padding-left: 20px;">Misc</h5>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Description:</div></label>
        <div class="p-col">
            <input id="additionalNote" type="text" [(ngModel)]="chargePointForm.description" pInputText> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Latitude:</div></label>
        <div class="p-col">
            <input id="additionalNote" type="text" [(ngModel)]="chargePointForm.locationLatitude"  pInputText> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Longitude:</div></label>
        <div class="p-col">
            <input id="additionalNote" type="text" [(ngModel)]="chargePointForm.locationLongitude" pInputText> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Additional Note:</div></label>
        <div class="p-col">
            <input id="additionalNote" type="text" [(ngModel)]="chargePointForm.note" pInputText> 
        </div>
    </div>
    
    <p-footer>
        <button class="btn btn-success" (click)="saveNewCharger()">Save</button>
        <button class="btn btn-danger" (click)="hideDialog()">Cancel</button>
    </p-footer>
</p-dialog>
<p-dialog [(visible)]="showGraph" [modal]="true"  [style]="{
    minWidth: '1000px',
   'z-index': '999'
 }">
    <ng-template pTemplate="header">
        Charger Utilization
    </ng-template>  
    <charger-graph *ngIf="showGraph" [chargerBoxId]="chargerBoxIdGraph"></charger-graph>
</p-dialog>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "var(--white)" type = "timer" [fullScreen] = "true"><p style="color: var(--white)" > Loading... </p></ngx-spinner>