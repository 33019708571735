<div style="padding-top: 40px">

    <div class="p-grid nested-grid" style="margin:0px">
        <div class="p-col-12">
            <div class="p-grid">
                <div class="p-col">
                    <a routerLink="/charge-point">
                        <div class="box">
                            Number of Charge Points <br />
                            <strong>{{homeRes.numChargeBoxes}}</strong>
                        </div>
                    </a>
                </div>
                <div class="p-col">
                    <a routerLink="/live-transactions">
                        <div class="box">
                            Number of Active Transactions<br />
                            <strong>{{homeRes.numTransactions}}</strong>
                        </div>
                    </a>
                </div>
                <div class="p-col">
                    <a routerLink="/reservations">
                        <div class="box">
                            Number of Active Reservation<br />
                            <strong>{{homeRes.numReservations}}</strong>
                        </div>
                    </a>
                </div>
                <!-- <div class="p-col">
                <div class="box" >
                    % Utilization (Today)<br/>
                     <span> <strong style="color: green !important;">{{utilizationPer | number}} %</strong><br/></span>  
                 </div>
            </div> -->

            </div>
            <br />
            <br />
            <div class="p-grid">

                <div class="p-col">

                </div>
                <!-- <div class="p-col-11"> 
               <a routerLink="/connector-status"><div class="box-bottom" style="width: auto;">
                     <div class="p-formgroup-inline">
                        <div class="p-field" >
                            <div class="p-0 "> <strong style="font-size: 16px;">Connector Status: </strong>  </div>
                        </div>
                        
                        <div class="p-field" style="margin-right:0.5rem  !important;">
                            <div class="pb-1 counter connected"> Available: {{homeRes.statusCountMap.Available ? homeRes.statusCountMap.Available : 0 }}</div>
                        </div>
                        <div class="p-field" style="margin-right:0.5rem  !important;">
                            <div class="pb-1 counter connected"> Charging: {{homeRes.statusCountMap.Charging ? homeRes.statusCountMap.Charging : 0 }} </div>
                        </div>
                        <div class="p-field" style="margin-right:0.5rem  !important;">
                            <div class="pb-1 counter connected"> Connected: {{homeRes.statusCountMap.Connected ? homeRes.statusCountMap.Connected : 0 }} </div>
                        </div> 
                        <div class="p-field" style="margin-right:0.5rem  !important;">
                            <div class="pb-1 counter available">  Completed: {{homeRes.statusCountMap.Finishing ? homeRes.statusCountMap.Finishing : 0 }} </div>
                        </div>
                        <div class="p-field " style="margin-right:0.5rem  !important;">
                            <div class="pb-1 counter available"> Preparing:  {{homeRes.statusCountMap.Preparing ? homeRes.statusCountMap.Preparing : 0 }}  </div>
                        </div>
                        <div class="p-field" style="margin-right:0.5rem  !important;">
                            <div class="pb-1 counter available"> Reserved:  {{homeRes.statusCountMap.Reserved ? homeRes.statusCountMap.Reserved : 0 }}  </div>
                        </div>
                        <div class="p-field" style="margin-right:0.5rem  !important;">
                            <div class="pb-1 counter stopped"> Unavailable:  {{homeRes.statusCountMap.Unavailable ? homeRes.statusCountMap.Unavailable : 0 }}  </div>
                        </div>
                       <div class="p-field" style="margin-right:0.5rem  !important;">
                            <div class="pb-1 counter stopped">  Faulted:  {{homeRes.statusCountMap.Faulted ? homeRes.statusCountMap.Faulted : 0 }}  </div>
                        </div>
                        
                        </div>                
                </div></a> 
            </div> -->
                <div class="p-col">

                </div>

            </div>
        </div>

    </div>
</div>
    <div class="header">
        <h4><span> Live Charger Summary</span></h4>
    </div>
    <div class="filter-container">
        <div class="p-formgroup-inline">
            <div class="p-field">
                <p-calendar appendTo="body" [(ngModel)]="selectedDatesByDaily" [showIcon]="true" ></p-calendar>
            </div>
            <div class="p-field">
                <span><button pButton type="button" label="Submit" style="height: 38px;" (click)="getSummary()"></button></span>
            </div>
            <div class="p-field">
                <span><button pButton type="button" label="CSV" style="height: 38px;" (click)="dt.exportCSV()"></button></span>
            </div>
        </div>
    </div>
 <div class="table">
    <p-table #dt [columns]="cols" [value]="liveSummaryArray"  [autoLayout]="true">
        <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
                <col style="width:60px">
                <col style="width:100px">
                <col style="width:100px">
                <col style="width:100px">
                <col style="width:100px">
                <col style="width:100px">
                
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    <span style="font-size: 12px;">{{col.header}}
                    </span>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-driver let-i="rowIndex">
            <tr>
                <td>{{driver.hub}}</td>
                <td> {{driver.totalMappedChargers}}</td>
                <td> {{driver.totalActiveChargers}}</td>
                <td>{{driver.totalConnectedChargers}} </td>
                <td>{{driver.totalIdle}}</td>
                <td>{{driver.totalBreakDownCharger}}</td>
                
                
            </tr>
        </ng-template>
    </p-table>
</div>

<div class="header">
    <h4><span> Utilization</span></h4>
</div>
<div style="padding: 40px;">

    <div class="p-grid p-justify-end">
        <div class="p-col">

        </div>
        <div class="p-col-4">
            <p-dropdown [options]="accessTypes" (onChange)="onSelect()" [(ngModel)]="accessType"></p-dropdown>
        </div>

        <div class="p-col-3" *ngIf="accessType == 'ALL_DAILY'">
            <div>
                <p-calendar appendTo="body" (onSelect)="onSelect()" [(ngModel)]="selectedDates" [showIcon]="true"
                    selectionMode="range"></p-calendar>
            </div>
        </div>
        <div class="p-col-3" *ngIf="accessType == 'ALL_HOURLY'">
            <div>
                <p-calendar appendTo="body" (onSelect)="onSelect()" [(ngModel)]="selectedDate" [showIcon]="true">
                </p-calendar>
            </div>
        </div>
    </div>
    <p-chart type="line" #linechart [data]="basicData" [options]="basicOptions" height="60vh"></p-chart>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="var(--white)" type="timer" [fullScreen]="true">
    <p style="color: var(--white)"> Loading... </p>
</ngx-spinner>