<div class="header">
    <h4><span> Past Transaction</span></h4>
</div>
<div class="filter-container">
    <div class="p-formgroup-inline">
        <div class="p-field">
            <p-calendar appendTo="body"   [(ngModel)]="selectedDates" [showIcon]="true" selectionMode="range"></p-calendar>
        </div>
        <div class="p-field">
            <span><button pButton type="button" label="Submit" style="height: 38px;" (click)="getPastTransactions()"></button></span>
        </div>
        <div class="p-field">
            <span><button pButton type="button" label="CSV" style="height: 38px;" (click)="dt.exportCSV()"></button></span>
        </div>
    </div>
</div>

<div class="table">
<p-table #dt [columns]="cols" [value]="data" [paginator]="true" [rows]="50" [autoLayout]="true">
    <ng-template pTemplate="caption">
        <div class="p-formgroup-inline">
        <div class="p-field">
            <div class="pb-1 counter connected"> Completed Transaction: {{totalCount}} </div>
        </div>
        <!-- <div class="p-field">
            <div class="pb-1 counter total"> Total Cancelled : {{cancelledCount}} </div>
        </div> -->
        <div class="p-field">
            <input  class="form-control" type="text" style="width: 250px;" pInputText size="50" placeholder="All Filter"
            (input)="dt.filterGlobal($event.target.value, 'contains')" lazy="false" />
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns">
              <th [ngStyle]="{'width': col.width}">
                  <ng-container [ngSwitch]="col.field">
                     <ng-container *ngSwitchDefault>{{ col.header }}</ng-container>
                   </ng-container>
              </th>
        </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-columns="columns" let-rowIndex="rowIndex">
        <tr>
            <ng-container *ngFor="let col of columns" >
            <td>
                <ng-container [ngSwitch]="col.field">
                    <ng-container *ngSwitchCase="'createdTimeStamp'">
                        <span *ngIf="data.createdTimeStamp"> {{ data.createdTimeStamp | date: "yyyy-MM-dd" }} at {{ data.createdTimeStamp | date: "shortTime" }}</span> 
                    </ng-container>
                    <ng-container *ngSwitchCase="'payment'">
                       <span *ngIf="data.payment!= undefined">Rs. </span>{{ data.payment }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'vehicleNumber'">
                        <span *ngIf="data.vehicle.vehicleNumber"> {{data.vehicle.vehicleNumber}}</span><br/>
                        <span *ngIf="data.userName">{{data.userName}}<br/>  {{data.userPhone}}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'charge'">   
                      <span *ngIf= "data.startTimestampDT"> Start : {{ data.startTimestampDT | date: "dd-MMM-yy" }} at {{ data.startTimestampDT | date: "shortTime" }} <br/></span>
                      <span *ngIf= "data.stopTimestampDT">Stop :  {{ data.stopTimestampDT | date: "dd-MMM-yy" }} at {{ data.stopTimestampDT | date: "shortTime" }}</span> 
                     </ng-container>
                    <ng-container *ngSwitchCase="'stopReason'">
                        <span *ngIf="data.stopReason=='COMPLETED'"> Completed</span>
                        <span *ngIf="data.stopReason!='COMPLETED'"> {{data.stopReason}}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'sno'">
                           {{rowIndex +1 }}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{data[col.field]}}
                    </ng-container>
                </ng-container>
            </td>
            </ng-container>
        </tr>
    </ng-template>
</p-table>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "var(--white)" type = "timer" [fullScreen] = "true"><p style="color: var(--white)" > Loading... </p></ngx-spinner>
