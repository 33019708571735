import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReservationsService } from 'src/app/services/reservations.service';
import Swal from 'sweetalert2';
import { TransactionsService } from '../../services/transactions.service';


@Component({
  selector: 'app-scheduled-reservation',
  templateUrl: './scheduled-reservation.component.html',
  styleUrls: ['./scheduled-reservation.component.css']
})
export class ScheduledReservationComponent implements OnInit {
  constructor(private transactionService: TransactionsService,private spinner: NgxSpinnerService,private reservationService : ReservationsService) { }


  cols: any[];
  data ;

  ngOnInit(): void {
    this.spinner.show();
    this.transactionService.getScheduleTransaction().subscribe(res=>{
      // console.log(res)
      this.data = res['response']
      console.log("DATA ",this.data)
      this.spinner.hide();
    })
    this.cols = [
      { field: 'reservationRequestId', header: 'Id',width : '67px' },
      //{ field: 'createdTimeStamp', header: 'Created Time' },
      { field: 'vehicleNumber', header: 'Vehicle'},
      { field: 'charger', header: 'Charger' },
      { field: 'user', header: 'User'},
      { field: 'userPhone', header: 'Phone No.'},
      { field: 'startDatetimeDT', header: 'Schedule Time' },
      { field: 'duration', header: 'Duration' },
      { field: 'power', header: 'Power Consumption(kWh)' }, 
      { field: 'payment', header: 'Payment' }, 
      { field: 'status', header: 'Status' }, 
      { field: 'deleteReservation', header: 'Action' },
      // { field: 'button', header: '' }
  ];
  }
  getDuration(start,end){
    let diff = (end - start)/1000;
    diff = Math.abs(diff);
    if (diff < 60) {
      return  diff.toFixed() + ' sec';
    }
    else if (diff >= 60 && diff < 3600) {
      let minute = Math.floor(diff / 60);
      return  minute + ' min';
    }
    else {
      let minute = Math.floor(diff/60);
      let hour = Math.floor(minute/60);
      minute = Math.floor(minute - hour*60);
      return  hour + ' hr ' + minute + ' min' ;
    }
  }
  deleteReservation(reservationID){
    Swal.fire({
      title: 'Are you sure you want to delete ReservationId: '+reservationID+'?',
      // text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.reservationService.cancelReservatoion(reservationID).subscribe(res => {
          // console.log(res)
          Swal.fire(
            'Deleted!',
            'Reservation Id '+reservationID+' has been deleted.',
            'success'
          )
          this.transactionService.getScheduleTransaction().subscribe(res=>{
            // console.log(res)
            this.data = res['response']
            console.log("DATA ",this.data)
            this.spinner.hide();
          })
        },error =>{
          // console.log(error)
          Swal.fire(
            'Some thing went Wrong!',
            error.error.errorMessage,
            'error'
          )
        })
      }
    })
    // console.log(reservationID)

  }
}
