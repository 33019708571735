import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';

@Component({
  selector: 'app-get-local-list-version',
  templateUrl: './get-local-list-version.component.html',
  styleUrls: ['./get-local-list-version.component.css']
})
export class GetLocalListVersionComponent implements OnInit {
  chargerList: any[];
  public charger;
  constructor(private dataSharingService :DataSharingService,private configService: ConfigService,private router : Router) { }

  ngOnInit(): void {
    if(this.dataSharingService.chargerPoints.length == 0)
    this.configService.getChargers().subscribe(res =>{
      // console.log(res['response']['cpList'])
      this.dataSharingService.chargerPoints=[]
      res['response']['cpList'].forEach(charger => {
        this.dataSharingService.chargerPoints.push({label: charger.chargeBoxId, value: charger.chargeBoxId})
       });
      this.chargerList = this.dataSharingService.chargerPoints;
    })
    else
    this.chargerList = this.dataSharingService.chargerPoints;
  }
  performAction(){
   let body = {
     "chargePointSelectList": [],

   }
  //  {
  //   "chargePointSelectList": [
  //     {
  //       "chargeBoxId": "string",
  //       "endpointAddress": "string",
  //       "endpointAddressSet": true,
  //       "ocppTransport": "SOAP",
  //       "soap": true
  //     }
  //   ]
  // }
  this.charger.forEach(charger => {
    body['chargePointSelectList'].push({ "chargeBoxId": charger , "ocppTransport": "JSON"})
  });
  // console.log(body)
   this.configService.performGetLocalListVersion(body).subscribe(res=>{
     // console.log(res)
     this.router.navigateByUrl('/tasks/detail/'+res['response']);
   },error =>{
     // console.log(error.error);
   })
  }
}
