import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';

@Component({
  selector: 'app-send-local-list',
  templateUrl: './send-local-list.component.html',
  styleUrls: ['./send-local-list.component.css']
})
export class SendLocalListComponent implements OnInit {

  constructor(private dataSharingService: DataSharingService,private configService: ConfigService,private router : Router) { }
  public charger ;
  public chargerList;
  public updateTypes=[]
  public updateType ='FULL'
  public listVersion
  public OCPPTags=[]
  public deleteOCPPTag
  public addOCPPTag
  ngOnInit(): void {
    if(this.dataSharingService.chargerPoints.length == 0)
    this.configService.getChargers().subscribe(res =>{
      // console.log(res['response']['cpList'])
      this.dataSharingService.chargerPoints=[]
      res['response']['cpList'].forEach(charger => {
        this.dataSharingService.chargerPoints.push({label: charger.chargeBoxId, value: charger.chargeBoxId})
       });
      this.chargerList = this.dataSharingService.chargerPoints;
    })
    else
    this.chargerList = this.dataSharingService.chargerPoints;
    this.configService.getOCPPTag().subscribe(res=>{
      // console.log(res)
      if(res['status'] == 'SUCCESS')
      res['response'].forEach(reservation => {
        this.OCPPTags.push({label : reservation , value : reservation})
      });
    })
    this.updateTypes = [{label: 'Full', value: 'FULL'},
                        {label: 'Differential', value: 'DIFFERENTIAL'}]
  }
  performAction(){
    let body ={
      "addUpdateList": [],
      "chargePointSelectList":[],
      "deleteList":[],
      "updateType": this.updateType,
      "listVersion": this.listVersion,
      "validWhenDifferential": true
    }
    this.charger.forEach(charger => {
      body['chargePointSelectList'].push({ "chargeBoxId": charger , "ocppTransport": "JSON"})
    });
    if(this.updateType != 'FULL'){
      body['deleteList'] = this.deleteOCPPTag
      body['addUpdateList'] = this.addOCPPTag
    }
    // {
    //   "addUpdateList": [
    //     "string"
    //   ],
    //   "chargePointSelectList": [
    //     {
    //       "chargeBoxId": "string",
    //       "endpointAddress": "string",
    //       "endpointAddressSet": true,
    //       "ocppTransport": "SOAP",
    //       "soap": true
    //     }
    //   ],
    //   "deleteList": [
    //     "string"
    //   ],
    //   "disjoint": true,
    //   "listVersion": 0,
    //   "updateType": "DIFFERENTIAL",  
    //   "validWhenDifferential": true
    // }
    this.configService.performSendLocalList(body).subscribe(res=>{
      // console.log(res)
      this.router.navigateByUrl('/tasks/detail/'+res['response']);
    },error =>{
      // console.log(error.error)
    })
  }
}
