<div class="header">
    <h4><span>Reservations</span></h4>
</div>
<!-- <div class="filter-container">
    <div class="p-formgroup-inline">
        <div class="p-field">
            <div class="pb-1"> User Id</div>
            <input [ngModelOptions]="{ standalone: true }" type="text" [(ngModel)]="userId" pInputText />
        </div>
        <div class="p-field">
            <div class="pb-1">&nbsp;</div>
            <button pButton type="button" label="Submit" (click)="getCharger()"></button>
        </div>
        <div class="button-container">
        <span style="margin: 0;position: absolute;top: 56%;-ms-transform: translateY(-50%);transform: translateY(-50%);"><button pButton type="button" label="Submit" (click)="getUser()"></button></span>
        </div>
    </div>
</div> -->

<div class="table">
<p-table #dt [columns]="cols" [value]="data" [paginator]="true" [rows]="50" [autoLayout]="true">
    <ng-template pTemplate="caption">
        <input  class="form-control" type="text" style="width: 250px;" pInputText size="50" placeholder="All Filter"
        (input)="dt.filterGlobal($event.target.value, 'contains')" lazy="false" />
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns">
              <th [ngStyle]="{'width': col.width}">
                  <ng-container [ngSwitch]="col.field">
                     <ng-container *ngSwitchDefault>{{ col.header }}</ng-container>
                   </ng-container>
              </th>
        </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-columns="columns" let-rowIndex="rowIndex">
        <tr>
            <ng-container *ngFor="let col of columns" >
            <td>
                <ng-container [ngSwitch]="col.field">
                    <ng-container *ngSwitchCase="'deleteUser'">
                       <!-- <button class="btn btn-danger">X</button> -->
                        <span style="padding-left: 33%;">
                       <a><i class="fa fa-window-close fa-lg"></i></a>
                       </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'deleteReservation'" style="text-align: center;">
                        <span style="padding-left: 33%;">
                          <!-- <button class="btn btn-danger" (click)="deleteChargeBox(data.chargeBoxPk)">x</button> -->
                          <a (click)="deleteReservation(data.reservationRequestId)"><i class="fa fa-window-close fa-lg"></i></a>
                      </span> 
                      </ng-container>
                    <ng-container *ngSwitchCase="'createdTimeStamp'">
                        <span *ngIf="data.reservation.createdTimeStamp"> {{ data.reservation.createdTimeStamp | date: "yyyy-MM-dd" }} at {{ data.reservation.createdTimeStamp | date: "shortTime" }}</span> 
                    </ng-container>
                    <ng-container *ngSwitchCase="'startDatetimeDT'"> 
                      <span *ngIf="data.reservation.startDatetimeDT">  {{ data.reservation.startDatetimeDT | date: "dd-MMM-yy" }} at {{ data.reservation.startDatetimeDT| date: "shortTime" }}</span> 
                    </ng-container>
                    <ng-container *ngSwitchCase="'walletBalance'">
                       <span *ngIf="data.walletBalance!= undefined">Rs. </span>{{ data.walletBalance }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'vehicleNumber'">
                        <span *ngIf="data.reservation.vehicleNumber!= undefined">{{data.reservation.vehicleNumber }} </span>
                     </ng-container>
                     <ng-container *ngSwitchCase="'charger'">
                        <span *ngIf="data.charger.displayName!= undefined">{{data.charger.displayName}} </span>
                     </ng-container>
                     <ng-container *ngSwitchCase="'user'">
                        <span *ngIf="data.reservation.userName!= undefined">{{data.reservation.userName}} </span>
                     </ng-container>
                     <ng-container *ngSwitchCase="'userPhone'">
                        <span *ngIf="data.reservation.phoneNo!= undefined">{{data.reservation.phoneNo}} </span>
                     </ng-container>
                     <ng-container *ngSwitchCase="'duration'">
                        <span *ngIf="data.reservation.expiryDatetimeDT"> {{getDuration(data.reservation.startDatetimeDT,data.reservation.expiryDatetimeDT)}}</span> 
                     </ng-container>
                     <ng-container *ngSwitchCase="'power'">
                        <span *ngIf="data.charger.power!= undefined">{{data.charger.power}} </span>
                     </ng-container>
                     <ng-container *ngSwitchCase="'payment'">
                        <span *ngIf="data.reservation.payment!= undefined"> Rs.{{data.reservation.payment}} </span>
                     </ng-container>
                     <ng-container *ngSwitchCase="'status'">
                        <span *ngIf="data.reservation.status!= undefined"> {{data.reservation.status}} </span>
                     </ng-container>
                    <ng-container *ngSwitchCase="'sno'">
                           {{rowIndex +1 }}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{data[col.field]}}
                    </ng-container>
                </ng-container>
            </td>
            </ng-container>
        </tr>
    </ng-template>
</p-table>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "var(--white)" type = "timer" [fullScreen] = "true"><p style="color: var(--white)" > Loading... </p></ngx-spinner>
