import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { DataSharingService } from '../../services/data-sharing.service';

@Component({
  selector: 'app-configv16',
  templateUrl: './configv16.component.html',
  styleUrls: ['./configv16.component.css']
})
export class Configv16Component implements OnInit {
  chargerList: any;

  constructor(private configService: ConfigService,private dataSharingService: DataSharingService) { 
  }
  ngOnInit(): void {
    console.log('HUB')
    console.log(this.dataSharingService.hub)
    this.configService.getChargers().subscribe(res =>{
       this.dataSharingService.chargerPoints=[]
       res['response']['cpList'].forEach(charger => {
        this.dataSharingService.chargerPoints.push({label: charger.chargeBoxId, value: charger.chargeBoxId})
       });
    })
  }

}
