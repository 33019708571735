<div class="header">
    <h4><span> Charge Points with OCPP v1.6</span></h4>
</div>
<div class="p-field p-grid ">
    <label for="chargeBox" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">ChargeBox ID:</div></label>
    <div class="p-col">
        <p-multiSelect appendTo="body" [options]="chargerList" [(ngModel)]="charger"></p-multiSelect>
    </div>
</div>
<div class="header">
    <h4><span> Parameters</span></h4>
</div>
<div class="p-field p-grid ">
    <label for="value" class="p-col-7"> <div style="width: 100%;text-align: right;color: var(--white);"> <em>No parameters required</em></div></label>
</div>
<div class="p-field p-grid">
    <label for="recipients" class="p-col-6" ><div style="width: 100%;text-align: right;color: var(--white);"></div></label>
    <div class="p-col">
        <button type="submit" class="btn btn-primary submit" (click)="performAction()">Perform</button>
    </div>
</div>
