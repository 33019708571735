import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChargePointComponent } from './components/charge-point/charge-point.component';
import { CancelReservationComponent } from './components/configv16/cancel-reservation/cancel-reservation.component';
import { ChangeAvailabilityComponent } from './components/configv16/change-availability/change-availability.component';
import { ChangeConfigurationComponent } from './components/configv16/change-configuration/change-configuration.component';
import { ClearCacheComponent } from './components/configv16/clear-cache/clear-cache.component';
import { Configv16Component } from './components/configv16/configv16.component';
import { GetConfigurationComponent } from './components/configv16/get-configuration/get-configuration.component';
import { GetLocalListVersionComponent } from './components/configv16/get-local-list-version/get-local-list-version.component';
import { RemoteStartTransactionComponent } from './components/configv16/remote-start-transaction/remote-start-transaction.component';
import { RemoteStopTransactionComponent } from './components/configv16/remote-stop-transaction/remote-stop-transaction.component';
import { ReserveNowComponent } from './components/configv16/reserve-now/reserve-now.component';
import { ResetComponent } from './components/configv16/reset/reset.component';
import { SendLocalListComponent } from './components/configv16/send-local-list/send-local-list.component';
import { TriggerMessageComponent } from './components/configv16/trigger-message/trigger-message.component';
import { UnlockConnectorComponent } from './components/configv16/unlock-connector/unlock-connector.component';
import { ConnectorDetailsComponent } from './components/connector-status/connector-details/connector-details.component';
import { ConnectorStatusComponent } from './components/connector-status/connector-status.component';
import { HomeComponent } from './components/home/home.component';
import { LiveTransactionsComponent } from './components/live-transactions/live-transactions.component';
import { LogsComponent } from './components/logs/logs.component';
import { PastReservationComponent } from './components/past-reservation/past-reservation.component';
import { PastTransactionsComponent } from './components/past-transactions/past-transactions.component';
import { ReservationsComponent } from './components/reservations/reservations.component';
import { ScheduledReservationComponent } from './components/scheduled-reservation/scheduled-reservation.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TaskDetailsComponent } from './components/tasks/task-details/task-details/task-details.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { UsersComponent } from './components/users/users.component';
import { CanActivateRouteGuard} from './guards/can-activate-route.guard'

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
 {
   path: 'home',
   component: HomeComponent,
   canActivate:[CanActivateRouteGuard]
 },
 {
  path: 'charge-point',
  component: ChargePointComponent,
  canActivate:[CanActivateRouteGuard]
 },
 {
  path: 'users',
  component: UsersComponent,
  canActivate:[CanActivateRouteGuard]
},
{
  path: 'transactions',
  component: TransactionsComponent,
  canActivate:[CanActivateRouteGuard]
},
{
  path: 'live-transactions',
  component: LiveTransactionsComponent,
  canActivate:[CanActivateRouteGuard]
},
{
  path: 'past-transactions',
  component: PastTransactionsComponent,
  canActivate:[CanActivateRouteGuard]
},
{
  path: 'reservations',
  component: ScheduledReservationComponent,
  canActivate:[CanActivateRouteGuard]
},
{
  path: 'past-reservations',
  component: PastReservationComponent,
  canActivate:[CanActivateRouteGuard]
},
{
  path: 'configv1.6',
  component: Configv16Component,
  children: [
    { path: '', redirectTo: 'changeAvailability', pathMatch: 'full' },
    { path: 'changeAvailability', component : ChangeAvailabilityComponent },
    { path: 'changeConfiguration', component : ChangeConfigurationComponent},
    { path: 'clearCache',  component : ClearCacheComponent },
    { path: 'remoteStartTransaction',  component : RemoteStartTransactionComponent},
    { path: 'remoteStopTransaction',  component : RemoteStopTransactionComponent },
    { path: 'reset',  component : ResetComponent },
    { path: 'unlockConnector',  component : UnlockConnectorComponent },
    { path: 'reserveNow',  component : ReserveNowComponent},
    { path: 'cancelReservation',  component : CancelReservationComponent},
    { path: 'getConfiguration',  component : GetConfigurationComponent},
    { path: 'getLocalListVersion',  component : GetLocalListVersionComponent},
    { path: 'sendLocalList',  component : SendLocalListComponent},
    { path: 'triggerMessage',  component :TriggerMessageComponent }
  ],
  canActivate:[CanActivateRouteGuard]
},
{
  path: 'tasks',
  component: TasksComponent,
  canActivate:[CanActivateRouteGuard]
},
{
  path: 'tasks/detail/:taskId',
  component: TaskDetailsComponent,
  canActivate:[CanActivateRouteGuard]
},
{
  path: 'settings',
  component: SettingsComponent,
  canActivate:[CanActivateRouteGuard]
},
{
  path: 'logs',
  component: LogsComponent,
 // canActivate:[CanActivateRouteGuard]
},
{
  path:'connector-status',
  component: ConnectorStatusComponent
},
{
  path:'charger-details/:chargeBoxPk',
  component: ConnectorDetailsComponent
}
,
{ path: '**', redirectTo: 'home' }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
