import { Injectable } from '@angular/core';
import { Headers } from '../model/api.headers';
import { HttpClient } from '@angular/common/http';
import { API_FETCH_CHARGE_POINT,API_ADD_CHARGE_POINT,API_DELETE_CHARGE_POINT,API_UPDATE_CHARGER_POINT } from '../config/index';


@Injectable({
  providedIn: 'root'
})
export class ChargePointService {

  constructor(private http : HttpClient) { }

  getChargePoints(chargeBoxId?,description?,heartbeatPeriod?,ocppVersion?){
   let url = API_FETCH_CHARGE_POINT
    let params = []
    if (chargeBoxId)
      params.push("chargeBoxId=" + chargeBoxId)
    if (description)
      params.push("description=" + description)
    if (heartbeatPeriod)
      params.push("heartbeatPeriod=" + heartbeatPeriod)
    if (ocppVersion)
      params.push("ocppVersion=" + ocppVersion)

    if (params.length > 0)
      url += "?" + params.join("&")
    return this.http.get(url,{headers : Headers.headers})
  }
  addChargePoint(data){
    return this.http.post(API_ADD_CHARGE_POINT,data,{headers: Headers.headers})
  }
  deleteChargePoint(chargeBoxPk){
      return this.http.delete(API_DELETE_CHARGE_POINT+chargeBoxPk,{headers: Headers.headers})
  }
  update(data){
    return this.http.put(API_UPDATE_CHARGER_POINT,data,{headers : Headers.headers})
  }
}
