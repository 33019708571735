import { Component, OnInit } from '@angular/core';
import { TransactionsService } from '../../services/transactions.service'

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {

  constructor(private transactionService: TransactionsService) { }

  ngOnInit(): void {
    this.transactionService.getScheduleTransaction().subscribe(res=>{
      // console.log(res)
    })
  }

}
