import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Headers } from '../model/api.headers';
import { BehaviorSubject } from 'rxjs';
import { API_CHECK_TOKEN , API_FETCH_ROLES, API_LOGIN ,API_FETCH_HUB_LIST} from '../config/index';
import { ConfigService } from './config.service';

@Injectable()
export class DataSharingService {
    public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public chargerPoints =[];
    public hub;
    public hubList=[];
    public name;
    constructor(private http: HttpClient, private configService: ConfigService) {
     }

    login(username,password){
      let body = {username: username,password: password};
      return this.http.post(API_LOGIN,body);
    }
    checkToken(){
      return this.http.get(API_CHECK_TOKEN,{headers: Headers.headers})
    }
    getRolesForUser(ssoId){
      return this.http.get(API_FETCH_ROLES+ssoId ,{headers: Headers.headers})
    }
    getHubList(){
      return this.http.get(API_FETCH_HUB_LIST ,{headers: Headers.headers}); 
    }
}

