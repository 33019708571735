import { Injectable } from '@angular/core';
import { Headers } from '../model/api.headers';
import { HttpClient } from '@angular/common/http';
import { API_FETCH_SETTING,API_UPDATE_SETTING } from '../config/index';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private http : HttpClient) { }

  getSetting(){
    return this.http.get(API_FETCH_SETTING,{headers: Headers.headers})
  }
  updateSetting(settingDetails){
    return this.http.post(API_UPDATE_SETTING,settingDetails,{headers: Headers.headers})
  }
}
