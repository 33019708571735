import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { SettingService } from '../../services/setting.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  settingDetails = {
    "heartbeat":0,
    "expiration": 0,
    "enabled": false,
    "from": "",
    "host": "",
    "username": "",
    "password": "",
    "protocol": "",
    "port": 0,
    "recipients": [],
    "enabledFeatures": []
  }
  OcppStationBooted: boolean = false;
  OcppStationStatusFailure: boolean = false;
  OcppStationWebSocketConnected: boolean = false;
  OcppStationWebSocketDisconnected: boolean = false;

  constructor(private settingService: SettingService,private spinner: NgxSpinnerService) { 
    this.getSettings();
  }


  ngOnInit(): void {
  }
  updateForm(){
    // console.log(this.settingDetails)
    if(typeof(this.settingDetails.recipients)== typeof("String"))
    { let recipientsArray = String (this.settingDetails.recipients).split(',');
      this.settingDetails.recipients = recipientsArray
    }
    this.settingDetails.enabledFeatures = []
    this.OcppStationBooted ? this.settingDetails.enabledFeatures.push('OcppStationBooted'): null ;
    this.OcppStationStatusFailure ? this.settingDetails.enabledFeatures.push('OcppStationStatusFailure'): null ;
    this.OcppStationWebSocketConnected ? this.settingDetails.enabledFeatures.push('OcppStationWebSocketConnected'): null ;
    this.OcppStationWebSocketDisconnected ? this.settingDetails.enabledFeatures.push('OcppStationWebSocketDisconnected'): null ;

    Swal.fire({
      title: 'Are you sure you want to update settings?',
      // text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Update it!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.settingService.updateSetting(this.settingDetails).subscribe(res=>{
        // console.log(res)
        Swal.fire(
          'Updated Successfully!',
          '',
          'success'
        )
      },error =>{
        Swal.fire(
          'Something went wrong!',
          error.error.errorMessage,
          'error'
        )
      })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          '',
          'error'
        )
      }
    })
    // this.settingService.updateSetting(this.settingDetails).subscribe(res=>{

    //   // console.log(res)
    // },error =>{
    //   // console.log(error)
    // })
  }
  getSettings(){
    this.spinner.show();
    this.settingService.getSetting().subscribe(res=>{
      // console.log(res)
      this.settingDetails = res['response']
      if(this.settingDetails.enabledFeatures.length > 0)
        { this.OcppStationBooted = this.settingDetails.enabledFeatures.includes('OcppStationBooted')
          this.OcppStationStatusFailure =this.settingDetails.enabledFeatures.includes('OcppStationStatusFailure')
          this.OcppStationWebSocketConnected=this.settingDetails.enabledFeatures.includes('OcppStationWebSocketConnected')
          this.OcppStationWebSocketDisconnected= this.settingDetails.enabledFeatures.includes('OcppStationWebSocketDisconnected')    
        }
      this.spinner.hide()
    })
  }

}
