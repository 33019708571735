import { Component } from '@angular/core';
import { DataSharingService } from './services/data-sharing.service';
import { Headers } from './model/api.headers';
import { CookieService } from 'ngx-cookie-service';
import { COOKIE_URL } from '../app/config/index';
import { PermissionForUsers } from '../app/guards/checkroles'
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'blusmart-altilium-ui';
  ssoId
  constructor(public dataSharingService : DataSharingService, private cookieService:CookieService, private router : Router,private spinner: NgxSpinnerService) { 
  }
  userName : string;
  password : string;
  name: string;
  isUserLogin;

  ngOnInit(): void { 
    this.dataSharingService.isUserLoggedIn.subscribe( value => {
      this.isUserLogin = value;
  });
    this.spinner.show();
    let accessToken = this.cookieService.get('accessToken')
    if(accessToken){
    Headers.clearHeaders();
    Headers.addHeaders({key : 'Authorization', value : 'Bearer '+ accessToken})
    }
    this.dataSharingService.checkToken().subscribe(res=>{
      if(res['status']=="SUCCESS")
      { this.ssoId = res['response'].sso_id
        localStorage.setItem('ssoId',res['response'].sso_id)
        this.dataSharingService.getRolesForUser(this.ssoId).subscribe(
          (res: any) => {
            localStorage.setItem("userRoles",JSON.stringify(res.response))
            this.isUserLogin = true;
            this.dataSharingService.isUserLoggedIn.next(true);
          }
        )
        if(res['response']['username']){ 
          let username = res['response']['username']
          let usernameArr = username.split('.')
          if(usernameArr.length == 3){
              this.name = usernameArr[0].charAt(0).toUpperCase() + usernameArr[0].slice(1).toLowerCase()
          } else{
              usernameArr = username.split('@')
              this.name = usernameArr[0].charAt(0).toUpperCase() + usernameArr[0].slice(1).toLowerCase()
          } 
          this.dataSharingService.name = this.name
        }
        this.spinner.hide();
        }
    },error =>{
      this.isUserLogin = false;
      this.dataSharingService.isUserLoggedIn.next(false);
      this.spinner.hide();
    }) 
  }
  login(f){
    this.dataSharingService.login(this.userName,this.password).subscribe(res =>{
      if(res['status']=="SUCCESS"){
        let response =res['response']
        Headers.clearHeaders();
        Headers.addHeaders({key : 'Authorization', value : 'Bearer '+ response.access_token})
        this.cookieService.set("accessToken", response.access_token, response.expires_in, "/",COOKIE_URL,null,"Lax");
        this.ssoId = res['response'].sso_id
        this.dataSharingService.checkToken().subscribe(data => {
        if(data['response']['username']){ 
          let username = data['response']['username']
          let usernameArr = username.split('.')
          if(usernameArr.length == 3){
              this.name = usernameArr[0].charAt(0).toUpperCase() + usernameArr[0].slice(1).toLowerCase()
          } else{
              usernameArr = username.split('@')
              this.name = usernameArr[0].charAt(0).toUpperCase() + usernameArr[0].slice(1).toLowerCase()
          } 
          this.dataSharingService.name = this.name
          }
        })
        localStorage.setItem('ssoId',res['response'].sso_id)
        this.dataSharingService.getRolesForUser(this.ssoId).subscribe(
          (res: any) => {
            localStorage.setItem("userRoles",JSON.stringify(res.response))
            this.dataSharingService.isUserLoggedIn.next(true);
            this.isUserLogin = true;
            this.router.navigateByUrl('/')
          }
        )
        
      }
    },error => {  window.alert(error.error.error_description)})

  }
  checkPermission(permissionString: String) {
    return PermissionForUsers.checkPermission(permissionString);
  }
}
