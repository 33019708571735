import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { TransactionsService } from '../../services/transactions.service';

@Component({
  selector: 'app-past-transactions',
  templateUrl: './past-transactions.component.html',
  styleUrls: ['./past-transactions.component.css']
})
export class PastTransactionsComponent implements OnInit {

  constructor(private transactionService: TransactionsService,private spinner: NgxSpinnerService) { }

  cols: any[];
  data ;
  selectedDates = [moment().startOf('d').toDate(), moment().startOf('d').add(1, 'd').toDate()];
  totalCount = 0;
  cancelledCount=0;
  
  ngOnInit(): void {
    this.getPastTransactions()
    this.cols = [
      { field: 'transactionId', header: 'Transaction Id' },
      //{ field: 'createdTimeStamp', header: 'Created Time' },
      { field: 'vehicleNumber', header: 'Vehicle'},
      { field: 'charger', header: 'Charger' ,width : '150px' },
      { field: 'startSoc', header: 'Initial SOC'},
      { field: 'endSoc', header: 'Final SOC'},
      { field: 'chargingTime', header: 'Charging Time' },
      { field: 'unit', header: 'Unit Consumption (kWh)' },
      { field: 'payment', header: 'Payment' },
      { field: 'charge', header: 'Charging History',width : '225px' }, 
      { field: 'stopReason', header: 'Stop Reason'}
      // { field: 'payment', header: 'Details' }
      // { field: 'button', header: '' }
  ];
  }
  getDuration(start,end){
    let diff = (end - start)/1000;
    diff = Math.abs(diff);
    if (diff < 60) {
      return  diff.toFixed() + ' sec';
    }
    else if (diff >= 60 && diff < 3600) {
      let minute = Math.floor(diff / 60);
      return  minute + ' min';
    }
    else {
      let minute = Math.floor(diff/60);
      let hour = Math.floor(minute/60);
      minute = Math.floor(minute - hour*60);
      return  hour + ' hr ' + minute + ' min' ;
    }
  }
  getPastTransactions(){
    this.spinner.show();
    let from = this.selectedDates[0].getTime()
    let to =this.selectedDates[1].getTime()
    let oneDay = 24 * 60 * 60 * 1000;
    if(Math.round(Math.abs((this.selectedDates[0].getTime() - this.selectedDates[1].getTime()) / oneDay)) > 31)
      {          Swal.fire(
                 'Required!',
                 'Please select 31 days date range.',
                 'error')
                 this.spinner.hide();
                 return
      }

    this.transactionService.getPastTransaction(from,to).subscribe(res=>{
      this.totalCount=0;
      this.cancelledCount = 0;
      this.data = res['response']
      this.data.forEach(transaction => {
          this.totalCount=this.totalCount+1
        transaction.transactionId =transaction.transaction.id
        transaction.createdTimeStamp = transaction.transaction.createdTimestamp
        transaction.vehicleNumber = transaction.vehicle.vehicleNumber + "|" + transaction.transaction.userName + "|" + transaction.transaction.userPhone
        transaction.charger = transaction.charger.displayName
        transaction.startSoc = transaction.transaction.startSoc == '-' ? 'NA' :transaction.transaction.startSoc+" %"
        transaction.endSoc = transaction.transaction.endSoc == '-' ? 'NA' : transaction.transaction.endSoc+" %"
        transaction.chargingTime = this.getDuration(transaction.transaction.startTimestampDT,transaction.transaction.stopTimestampDT)
        transaction.unit = Number(transaction.status.units).toFixed(2)
        transaction.charge = "Start : "+ formatDate(new Date(transaction.transaction.startTimestampDT) ,'yyyy-MM-dd HH:mm:ss', 'en-IST') +"|"+ "Stop : "+ formatDate(new Date(transaction.transaction.stopTimestampDT) ,'yyyy-MM-dd HH:mm:ss', 'en-IST')
        transaction.startTimestampDT = transaction.transaction.startTimestampDT
        transaction.stopTimestampDT = transaction.transaction.stopTimestampDT
        transaction.payment = Number(transaction.status.chargingCost).toFixed(2)
        transaction.stopReason = transaction.transaction.stopReason ? transaction.transaction.stopReason : 'NA';
        transaction.userName = transaction.transaction.userName ? transaction.transaction.userName : '';
        transaction.userPhone = transaction.transaction.userPhone ? transaction.transaction.userPhone : '';
      });
      this.spinner.hide();
    })
  }

}
