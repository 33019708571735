import { environment } from '../../environments/environment';

export const BASE_URL: string = environment.baseUrl;
export const COOKIE_URL: string = environment.cookieURL
export const API_UPLOAD_KML_FILE: string = `${BASE_URL}/api/v1/region/upload_kml`;

export const API_LOGIN : string = `${BASE_URL}/auth/login`;
export const API_CHECK_TOKEN : string = `${BASE_URL}/auth/check/token`;
export const API_FETCH_ROLES: string =`${BASE_URL}/api/user/permission/`;
export const API_FETCH_HUB_LIST: string =`${BASE_URL}/public/api/charge/stations`

//home
export const API_FETCH_HOME_STATUS: string =`${BASE_URL}/home/v2`;
export const API_FETCH_LOGS: string = `${BASE_URL}/home/v2/logs`;
export const API_FETCH_CONNECTOR: string =`${BASE_URL}/home/v2/connectorStatus`;
export const API_FETCH_CONNECTOR_DETAILS: string =`${BASE_URL}/api/chargepoints/v2/details/`;
export const API_FETCH_UTILIZATION: string = `${BASE_URL}/api/utilization`;
export const API_FETCH_UTILIZATION_ALL: string = `${BASE_URL}/api/utilization/hub/all`
export const API_FETCH_LIVE_SUMMARY: string = `${BASE_URL}/home/v2/summary`

//Reservation API
export const API_FETCH_RESERVATION: string =`${BASE_URL}/api/reservations/all/reservation`;
export const API_FETCH_CHARGE_BOX_LIST: string =`${BASE_URL}/public/api/chargepoints/charge/list`;

//User
export const API_FETCH_USER: string = `${BASE_URL}/api/user/get/all`;
export const API_UPDATE_USER: string = `${BASE_URL}/api/user/add`;
export const API_DELETE_USER: string =`${BASE_URL}/api/user/delete/`;

//ChargePoint
export const API_FETCH_CHARGE_POINT: string =`${BASE_URL}/api/chargepoints/v2/`;
export const API_ADD_CHARGE_POINT: string =`${BASE_URL}/api/chargepoints/v2/add`;
export const API_DELETE_CHARGE_POINT: string =`${BASE_URL}/api/chargepoints/v2/delete/`;
export const API_UPDATE_CHARGER_POINT : string =`${BASE_URL}//api/chargepoints/v2/update`;
//task 
export const API_FETCH_TASK: string =`${BASE_URL}/api/operations/tasks/v2/`;
export const API_DELETE_FINISHED_TASK: string =`${BASE_URL}/api/operations/tasks/v2/`;
export const API_GET_TASK_DETAILS: string =`${BASE_URL}/api/operations/tasks/v2/`;

//setting
export const API_FETCH_SETTING: string= `${BASE_URL}/api/setting/details`;
export const API_UPDATE_SETTING: string =`${BASE_URL}/api/setting/update`;

//reservation
export const API_FETCH_FUTURE_RESERVATION: string = `${BASE_URL}/api/reservations/all/future/reservation`;
export const API_FETCH_PAST_RESERVATION: string =`${BASE_URL}/api/reservations/all/past/reservation`;
export const API_FETCH_PAST_TRANSACTION: string = `${BASE_URL}/api/transaction/all/past/transactions`;
export const API_FETCH_FUTURE_TRANSACTION: string =`${BASE_URL}/api/transaction/all/future/transactions/?limit=300`
export const API_STOP_TRANSACTION: string = `${BASE_URL}/api/transaction/RemoteStopTransaction/`;
export const API_CANCEL_RESEVATION_BY_ID : string =`${BASE_URL}/api/reservations/`


//config
export const API_FETCH_CHARGER_POINT: string = `${BASE_URL}/api/operations/v1.6/v2/`;
export const API_FETCH_ACTIVE_RESERVATION_FOR_CHARGER: string =`${BASE_URL}/api/reservations/all/`;
export const API_FETCH_ACTIVE_TRANSACTION_FOR_CHARGER: string= `${BASE_URL}/api/transaction/get/`;
export const API_FETCH_OCPP_LIST: string = `${BASE_URL}/api/user/user-tag/all`;
export const API_POST_CHANGE_AVAILABILITY: string =`${BASE_URL}/api/operations/v1.6/v2/ChangeAvailability`;
export const API_POST_CANCEL_RESERVSTION: string =`${BASE_URL}/api/operations/v1.6/v2/CancelReservation`;
export const API_POST_CHANGE_CONFIGURATION: string = `${BASE_URL}/api/operations/v1.6/v2/ChangeConfiguration`;
export const API_POST_CLEAR_CACHE: string = `${BASE_URL}/api/operations/v1.6/v2/ClearCache`;
export const API_POST_GET_CONFIGURATION: string =`${BASE_URL}/api/operations/v1.6/v2/GetConfiguration`;
export const API_POST_GET_LOCAL_LIST_VERSION: string =`${BASE_URL}/api/operations/v1.6/v2/GetLocalListVersion`;
export const API_POST_REMOTE_START_TRANSACTION: string =`${BASE_URL}/api/operations/v1.6/v2/RemoteStartTransaction`; 
export const API_POST_REMOTE_STOP_TRANSACTION: string =`${BASE_URL}/api/operations/v1.6/v2/RemoteStopTransaction`; 
export const API_POST_RESERVE_NOW: string =`${BASE_URL}/api/operations/v1.6/v2/ReserveNow`;
export const API_POST_RESET: string =`${BASE_URL}/api/operations/v1.6/v2/Reset`;
export const API_POST_SEND_LOCAL_LIST : string =`${BASE_URL}/api/operations/v1.6/v2/SendLocalList`; 
export const API_POST_TRIGGER_MESSAGE: string = `${BASE_URL}/api/operations/v1.6/v2/TriggerMessage`;
export const API_POST_UNLOCK_CHARGER: string = `${BASE_URL}/api/operations/v1.6/v2/UnlockConnector`;

