import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TasksService } from '../../services/tasks.service'

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent implements OnInit {

  userId: String = "";
  cols: any[];
  data ;
  isSelected: boolean = true;
  taskId: any;
  constructor(private tasksService :TasksService,private spinner: NgxSpinnerService) { 
  }

  ngOnInit(): void {
    this.spinner.show();
    this.tasksService.getTasks().subscribe(res=>{
      // console.log(res)
      this.data = res['response']['taskList']
      this.spinner.hide();
    })
    this.cols = [
      { field: 'sno', header: 'S No.' },
      { field: 'taskId', header: 'Task Id' },
      { field: 'origin', header: 'Origin' },
      { field: 'startTimestamp', header: 'Srart Timestamp' },
      { field: 'endTimestamp', header: 'End Timestamp' },
      { field: 'responseRequest', header: 'Response/Request' }
      // { field: 'button', header: '' }
  ];
  
  }
  deleteFinished(){
    this.tasksService.clearFinishedTask().subscribe(res=>{
      // console.log(res)
      this.data= res['response']['taskList']
    },error=>{
      // console.log(error)
    })
  }
  openTask(taskId){
    this.isSelected = false;
    this.taskId = taskId
    // console.log(taskId)
  }
  back(){
    this.isSelected = true;
  }
}
