import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-reserve-now',
  templateUrl: './reserve-now.component.html',
  styleUrls: ['./reserve-now.component.css']
})
export class ReserveNowComponent implements OnInit {

  resetTypes: any[];
  userIds: { label: string; value: string; }[];

  constructor(private dataSharingService :DataSharingService,private configService: ConfigService,private userService : UsersService,private router : Router) { }
  public charger ;
  public chargerList;
  public existingReservationList;
  public connectorId;
  public expiryDate;
  public userId;

  ngOnInit(): void {
     if(this.dataSharingService.chargerPoints.length == 0)
    this.configService.getChargers().subscribe(res =>{
      // console.log(res['response']['cpList'])
      this.dataSharingService.chargerPoints=[]
      res['response']['cpList'].forEach(charger => {
        this.dataSharingService.chargerPoints.push({label: charger.chargeBoxId, value: charger.chargeBoxId})
       });
      this.chargerList = this.dataSharingService.chargerPoints;
    })
    else
    this.chargerList = this.dataSharingService.chargerPoints;
  this.resetTypes = [{label: 'Hard', value: 'HARD'},
                    {label: 'Soft', value: 'SOFT'}]
                    this.userService.getAllUser().subscribe(res=>{
                      let response = res['response']
                      response.forEach(user => {
                        this.userIds.push({ label : user.name+"_"+user.userPk , value: user.userPk})
                      });
                    })
  this.userIds = [{label: 'USER-1', value: 'INOPERATIVE'},
  {label: 'USER-2', value: 'OPERATIVE'}]
  }
  performAction(){
    let body= {
      "chargePointSelectList": [],
      "connectorId": this.connectorId,
      "expiry": this.expiryDate.toISOString(),
      "idTag": this.userId
    }
    this.charger.forEach(charger => {
      body['chargePointSelectList'].push({ "chargeBoxId": charger , "ocppTransport": "JSON"})
    });
    // console.log(body)
    this.configService.performReserveNow(body).subscribe(res=>{
      // console.log(res)
      this.router.navigateByUrl('/tasks/detail/'+res['response']);
    },error=>{
      // console.log(error)
    })
  }

}
