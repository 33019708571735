import { Component, OnInit,Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from '../services/data-sharing.service';
import { Headers } from '../model/api.headers';
import { CookieService } from 'ngx-cookie-service';
import { COOKIE_URL } from '../config/index';
import { PermissionForUsers } from '../guards/checkroles';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  @Input() isUserLogin: boolean;
  @Input() name : string;
  menuFlag:boolean = false;
  hubs
  hub: Number
  constructor(private router:Router,private dataSharingService: DataSharingService , private cookieService: CookieService,private route:ActivatedRoute) { }

  ngOnInit(): void {
    if(!this.dataSharingService.hub)
    this.dataSharingService.getHubList().subscribe(res=>{
      if(res['response'])
       { this.hubs=[{label: 'All', value : 0}]
         res['response'].forEach(hub => {
           this.hubs.push({label: hub['name'], value : hub['id']})
         });
         this.dataSharingService.hubList =this.hubs
         if(this.cookieService.get("hub"))
         {this.dataSharingService.hub = Number(this.cookieService.get("hub"))
          this.hub =Number(this.cookieService.get("hub"))
          }
        else{
          this.dataSharingService.hub = this.hubs[0].value
          this.cookieService.set("hub", this.hubs[0].value, null, "/",COOKIE_URL,null,"Lax");
          this.hub = this.dataSharingService.hub
        }
       }
   })
  }
  logout(){
    Headers.clearHeaders();
    this.cookieService.deleteAll('/', COOKIE_URL);
    this.dataSharingService.name = null  
    this.dataSharingService.isUserLoggedIn.next(false);
  }

  menuToggle(){
    this.menuFlag = !this.menuFlag;
  }
  checkPermission(permissionString: String) {
    return PermissionForUsers.checkPermission(permissionString);
  }

  onChange(){
    this.dataSharingService.hub = this.hub
    this.cookieService.set("hub",  this.dataSharingService.hub, null, "/",COOKIE_URL,null,"Lax");
    if(this.router.url == '/home')
       window.location.reload();
    else
    this.redirectTo(this.router.url)
  }

  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
 }
}
