<div class="top-nav">
  <div class="row d-flex flex-wrap align-items-center" style="width: 100%;">
    <div class="col-6 col-6 ">
      <div class="logo " style="flex-grow: 1; color: var(--white);">
       <a (click)="menuToggle()"> <i class="fa fa-bars fa-lg" style="padding-right: 15px; cursor: pointer;"></i></a>
        <img src="assets/logo1.png" height="30px" />
      </div>
    </div>
    <div class="col-6 col-6 ">
    <div class="logout" style="flex-grow: 8;">
        <p-dropdown [options]="hubs" [(ngModel)]="hub" (onChange)="onChange()"  name="hub"></p-dropdown>
        <span *ngIf="name" style="font-size: 16px; padding-left: 10px;">Hello, {{name}} &nbsp; |</span>
       <a (click)='logout()' style="cursor: pointer; padding-left: 20px;font-size: 16px;"><i class="fa fa-sign-out-alt" aria-hidden="true" ></i> Logout</a>
    </div>
    </div>
  </div>
</div>
<div style="display: flex;">
<div class="menu" *ngIf="menuFlag">    
    <ul>
        <li *ngIf="checkPermission('MENU_HOME')">
          <a routerLink="/home" routerLinkActive="menu-active" (click)="menuToggle()"><i class="fa fa-home" aria-hidden="true"></i><span>Home</span></a>
        </li>
        <li *ngIf="checkPermission('MENU_CHARGE_POINT')">
          <a routerLink="/charge-point" routerLinkActive="menu-active" (click)="menuToggle()"><i class="fa fa-bolt" aria-hidden="true"></i><span
              >Charge Point</span></a>
        </li>
        <li *ngIf="checkPermission('MENU_USERS')">
          <a routerLink="/users" routerLinkActive="menu-active" (click)="menuToggle()"><i class="fa fa-users"
              aria-hidden="true"></i>
            <span >Users</span></a>
        </li>
        <!-- <li *ngIf="true">
          <a routerLink="/reservations" routerLinkActive="menu-active"><i class="fa fa-calendar" aria-hidden="true"></i><span
              >Reservations</span></a>
        </li>
        <li *ngIf="true">
          <a routerLink="/transactions" routerLinkActive="menu-active"><i class="fa fa-money" aria-hidden="true"></i><span
              >Transactions</span></a>
        </li> -->
        <li style="height: 4px; background: var(--grey-6);
        ">

        </li>
        <li *ngIf="checkPermission('MENU_LIVE_TRANSACTION')">
          <a routerLink="/live-transactions" routerLinkActive="menu-active" (click)="menuToggle()"><i class="fa fa-bolt" aria-hidden="true"></i><span
              >Live Transactions</span></a>
        </li>
        <li *ngIf="checkPermission('MENU_PAST_TRANSACTION')">
          <a routerLink="/past-transactions" routerLinkActive="menu-active" (click)="menuToggle()"><i class="fa fa-bolt" aria-hidden="true"></i><span
              >Past Transactions</span></a>
        </li>
        <li *ngIf="checkPermission('MENU_SCHEDULED_RESERVATION')">
          <a routerLink="/reservations" routerLinkActive="menu-active" (click)="menuToggle()"><i class="fa fa-bolt" aria-hidden="true"></i><span
              >Reservations</span></a>
        </li>
        <li *ngIf="checkPermission('MENU_PAST_RESERVATION')">
          <a routerLink="/past-reservations" routerLinkActive="menu-active" (click)="menuToggle()"><i class="fa fa-bolt" aria-hidden="true"></i><span
              >Past Reservations</span></a>
        </li>

        <li style="height: 4px; background: var(--grey-6);">

        </li>
  
        <li *ngIf="checkPermission('MENU_CONFIG')">
          <a routerLink="/configv1.6"  routerLinkActive="menu-active" (click)="menuToggle()"><i class="fa fa-cogs"
              aria-hidden="true"></i><span >Config v1.6</span></a>
        </li>
        <li *ngIf="checkPermission('MENU_TASK')">
            <a routerLink="/tasks"  routerLinkActive="menu-active" (click)="menuToggle()"><i class="fa fa-tasks"
                aria-hidden="true"></i><span >Tasks</span></a>
          </li>
          <!-- <li *ngIf="true">
            <a routerLink="/reports"  routerLinkActive="menu-active"><i class="fa fa-file-alt"
                aria-hidden="true"></i><span >Reports</span></a>
          </li> -->
          <li *ngIf="checkPermission('MENU_SETTING')">
            <a routerLink="/settings"  routerLinkActive="menu-active" (click)="menuToggle()"><i class="fa fa-wrench"
                aria-hidden="true"></i><span >Settings</span></a>
          </li>
          <li>
            <a routerLink="/logs"  routerLinkActive="menu-active" (click)="menuToggle()"><i class="fa fa-wrench"
                aria-hidden="true"></i><span >logs</span></a>
          </li>
          <!-- <li *ngIf="true">
            <a routerLink="/log"  routerLinkActive="menu-active"><i class="fa fa-file-text"
                aria-hidden="true"></i><span >Log</span></a>
          </li> -->
          <!-- <li *ngIf="true">
            <a routerLink="/about"  routerLinkActive="menu-active"><i class="fa fa-info-circle"
                aria-hidden="true"></i><span >About</span></a>
          </li> -->

      </ul>


</div>
<div class="content">
  <router-outlet></router-outlet>
</div>
</div>