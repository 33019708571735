<div class="header">
    <h4><span> User Overview </span></h4>
</div>
<!-- <div class="filter-container">
    <div class="p-formgroup-inline">
        <div class="p-field">
            <div class="pb-1"> User Id</div>
            <input [ngModelOptions]="{ standalone: true }" type="text" [(ngModel)]="userId" pInputText />
        </div>
        <div class="button-container">
        <span style="margin: 0;position: absolute;top: 56%;-ms-transform: translateY(-50%);transform: translateY(-50%);"><button pButton type="button" label="Submit" (click)="getUser()"></button></span>
        </div>
    </div>
</div> -->

<div class="table">
<p-table #dt [columns]="cols" [value]="data" [paginator]="true" [rows]="50" [autoLayout]="true" >
    <ng-template pTemplate="caption">
        <div style="display: flex; width: 100%;">
            <input  class="form-control" type="text" style="width: 250px;" pInputText size="50" placeholder="All Filter"
            (input)="dt.filterGlobal($event.target.value, 'contains')" lazy="false" />
            <div style="text-align: end ;padding-bottom: 22px; padding-right: 20px; width: 100%;">
            <button class="btn btn-success" style="height: 33px;" (click)="openAddNew()">Add New</button>
            </div>
        </div>
        <div class="p-formgroup-inline">
            <div class="p-field">
                <div class="pb-1 counter total"> Total Chargers: {{ this.totalCharger }} </div>
            </div>
            <div class="p-field">
                <div class="pb-1 counter connected"> Connected (Not charging) : {{connectedCount}} </div>
            </div>
            <div class="p-field">
                <div class="pb-1 counter connected"> Charging : {{chargingCount}} </div>
            </div>
            <div class="p-field">
                <div class="pb-1 counter available"> Complete : {{completeCount}} </div>
            </div>
            <div class="p-field">
                <div class="pb-1 counter available"> Reserved Chargers: {{reservedCount}} </div>
            </div>
            <div class="p-field">
                <div class="pb-1 counter available"> Available Chargers: {{availableCount}} </div>
            </div>
            <div class="p-field">
                <div class="pb-1 counter stopped"> Unavailable Chargers: {{unavailableCount}} </div>
            </div>
            <div class="p-field">
                <div class="pb-1 counter stopped"> Faulted Chargers: {{faultedCount}} </div>
            </div>  
            </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns">
              <th [ngStyle]="{'width': col.width}">
                  <ng-container [ngSwitch]="col.field">
                     <ng-container *ngSwitchDefault>{{ col.header }}</ng-container>
                   </ng-container>
              </th>
        </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data let-columns="columns" let-rowIndex="rowIndex">
        <tr>
            <ng-container *ngFor="let col of columns" >
            <td>
                <ng-container [ngSwitch]="col.field">
                    <ng-container *ngSwitchCase="'deleteUser'">
                       <!-- <button class="btn btn-danger">X</button> -->
                        <span style="padding-left: 33%;">
                       <a (click)="deleteUser(data.userPk)"><i class="fa fa-window-close fa-lg"></i></a>
                       </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'lastTransaction'">
                      <span *ngIf="data.lastTransaction"> {{ data.lastTransaction | date: "yyyy-MM-dd" }} at {{ data.lastTransaction | date: "shortTime" }}</span> 
                    </ng-container>
                    <ng-container *ngSwitchCase="'walletBalance'">
                       <span *ngIf="data.walletBalance!= undefined">Rs. </span>{{ data.walletBalance }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'sno'">
                           {{rowIndex +1 }}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{data[col.field]}}
                    </ng-container>
                </ng-container>
            </td>
            </ng-container>
        </tr>
    </ng-template>
</p-table>
</div>

<p-dialog [(visible)]="showAddNew" [modal]="true" [responsive]="true"  [minY]="70" [maximizable]="false" [baseZIndex]="1" [style]="{
     minWidth: '700px',
    'z-index': '999'
  }" >
    <ng-template pTemplate="header">
        Add New User
    </ng-template>  

    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Name:</div></label>
        <div class="p-col">
            <input id="name" type="text" pInputText [(ngModel)]="userForm.name"> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Email:</div></label>
        <div class="p-col">
            <input id="email" type="text" pInputText [(ngModel)]="userForm.email"> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Phone:</div></label>
        <div class="p-col">
            <input id="phone" type="number" pInputText [(ngModel)]="userForm.phone"> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="tenantId" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Tenant Id:</div></label>
        <div class="p-col">
            <input id="tenantId" type="number" pInputText [(ngModel)]="userForm.tenantId"> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Sex:</div></label>
        <div class="p-col">
            <p-dropdown [options]="sexOption" [(ngModel)]="userForm.sex"></p-dropdown>
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Additional Note:</div></label>
        <div class="p-col">
            <input id="additionalNote" type="text" pInputText [(ngModel)]="userForm.note"> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Birth Day</div></label>
        <div class="p-col">
            <p-calendar appendTo="body" [(ngModel)]="birthday" [showIcon]="true"></p-calendar>
        </div>
    </div>

    <h5 style="font-weight: 700; padding-left: 20px;">Address</h5>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Street:</div></label>
        <div class="p-col">
            <input id="street" type="text" [(ngModel)]="userForm.address.street" pInputText> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">House Number:</div></label>
        <div class="p-col">
            <input id="houseNumber" type="text" [(ngModel)]="userForm.address.houseNumber" pInputText> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Zip code:</div></label>
        <div class="p-col">
            <input id="sex" type="text" [(ngModel)]="userForm.address.zipCode" pInputText> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">City:</div></label>
        <div class="p-col">
            <input id="city" type="text" [(ngModel)]="userForm.address.city" pInputText> 
        </div>
    </div>
    <div class="p-field p-grid ">
        <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Country:</div></label>
        <div class="p-col">
            <p-dropdown [options]="countryList" [(ngModel)]="userForm.address.country"></p-dropdown>
        </div>
    </div>
    
    <p-footer>
        <button class="btn btn-success" (click)="addUser()">Save</button>
        <button class="btn btn-danger"(click)="openAddNew()">Cancel</button>
    </p-footer>
</p-dialog>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "var(--white)" type = "timer" [fullScreen] = "true"><p style="color: var(--white)" > Loading... </p></ngx-spinner>
