import { HttpClient } from '@angular/common/http';
import { Headers } from '../model/api.headers';
import { Injectable } from '@angular/core';
import { API_FETCH_RESERVATION ,API_FETCH_CHARGE_BOX_LIST, API_FETCH_PAST_RESERVATION, API_CANCEL_RESEVATION_BY_ID } from '../config/index';
import { DataSharingService } from './data-sharing.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationsService {

  constructor(private http: HttpClient, private dataSharingService : DataSharingService) { }

  getReservations(chargeBoxId?,reservationStatus?,userEmail?, periodType?, from?, to?){
    chargeBoxId = chargeBoxId ? chargeBoxId : '';
    reservationStatus = reservationStatus ? reservationStatus : '';
    userEmail =userEmail ? userEmail : '';
    periodType=periodType ? periodType : '';
    from=from ? from : '';
    to= to ? to : '';
    return this.http.get(API_FETCH_RESERVATION + chargeBoxId+ reservationStatus+ userEmail+ periodType+ from+ to,{headers : Headers.headers})
  }

  getChargeBoxList(){
    return this.http.get(API_FETCH_CHARGE_BOX_LIST,{headers : Headers.headers})
  }
  getPastReservation(){
    let url = API_FETCH_PAST_RESERVATION
    if(this.dataSharingService.hub)
      url = url+'?stationId='+this.dataSharingService.hub
    return this.http.get(url,{headers: Headers.headers})
  }
  cancelReservatoion(reservationRequestId){
    let body={}
    return this.http.post(API_CANCEL_RESEVATION_BY_ID+reservationRequestId+'/cancel',body,{headers: Headers.headers})
  }

}
