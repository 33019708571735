<div class="header">
    <h4><span> Connector Status </span></h4>
</div>

<div class="table">
    <p-table #dt [columns]="cols" [value]="data" [paginator]="true" [rows]="50" [autoLayout]="true" >
        <ng-template pTemplate="caption">
            <div style="display: flex; width: 100%;">
                <input  class="form-control" type="text" style="width: 250px;" pInputText size="50" placeholder="All Filter"
                (input)="dt.filterGlobal($event.target.value, 'contains')" lazy="false" />
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <ng-container *ngFor="let col of columns">
                  <th [ngStyle]="{'width': col.width}">
                      <ng-container [ngSwitch]="col.field">
                         <ng-container *ngSwitchDefault>{{ col.header }}</ng-container>
                       </ng-container>
                  </th>
            </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data let-columns="columns" let-rowIndex="rowIndex">
            <tr>
                <ng-container *ngFor="let col of columns" >
                <td>
                    <ng-container [ngSwitch]="col.field">

                        <ng-container *ngSwitchCase="'chargeBoxId'">
                          <a style="text-decoration: none;" [routerLink]= "['/charger-details', data.chargeBoxPk]"> {{ data.chargeBoxId }}</a> 
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{data[col.field]}}
                        </ng-container>
                    </ng-container>
                   
                </td>
                </ng-container>
            </tr>
        </ng-template>
    </p-table>
    </div>
