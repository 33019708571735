import { Injectable } from '@angular/core';
import { Headers } from '../model/api.headers';
import { HttpClient } from '@angular/common/http';
import { API_FETCH_HOME_STATUS, API_FETCH_LOGS ,API_FETCH_CONNECTOR,
  API_FETCH_CONNECTOR_DETAILS, API_FETCH_UTILIZATION, API_FETCH_UTILIZATION_ALL, API_FETCH_LIVE_SUMMARY} from '../config/index';
import { DataSharingService } from './data-sharing.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient,private dataSharingService : DataSharingService) { }
  getHomeData(){
    let url = API_FETCH_HOME_STATUS
    if(this.dataSharingService.hub)
      url = url+'?stationId='+this.dataSharingService.hub
    return this.http.get(url,{headers: Headers.headers})
  }
  getLogs(){
    return this.http.get(API_FETCH_LOGS, {headers: Headers.headers})
  }
  getConnectors(){
    return this.http.get(API_FETCH_CONNECTOR,{headers: Headers.headers})
  }
  getConnectorDetails(connectorPk){
    return this.http.get(API_FETCH_CONNECTOR_DETAILS+connectorPk,{headers: Headers.headers})
  }
  getUtilization(accessType,from,to,chargeBoxId)
  { //api/utilization?accessType=HOURLY&from=1616092200000&to=1616351400000&chargeBoxId=ALL_CHARGERS
    accessType= accessType ? '?accessType='+accessType : '';
    from = from ? '&from='+from : '';
    to = to ? '&to='+to : '';
    chargeBoxId = chargeBoxId?  '&displayName='+chargeBoxId  : '';
    return this.http.post(API_FETCH_UTILIZATION+ accessType+from+to+chargeBoxId,{},{headers: Headers.headers})
  }
  getAllUtilization(accessType,from,to,hub)
  { //api/utilization?accessType=HOURLY&from=1616092200000&to=1616351400000&chargeBoxId=ALL_CHARGERS
    accessType= accessType ? '?accessType='+accessType : '';
    from = from ? '&from='+from : '';
    to = to ? '&to='+to : '';
    hub = hub ? '&hubName='+hub:'';
    return this.http.get(API_FETCH_UTILIZATION_ALL+ accessType+from+to+hub,{headers: Headers.headers})
  }
  getLiveSummary(startTime){
    startTime = "?time="+startTime; 
    return this.http.get(API_FETCH_LIVE_SUMMARY+startTime, {headers: Headers.headers})
  }
}
