import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerModule } from "ngx-spinner";
import {  ChartModule } from 'primeng/chart';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './components/home/home.component';
import { ChargePointComponent } from './components/charge-point/charge-point.component';
import { UsersComponent } from './components/users/users.component';
import { ReservationsComponent } from './components/reservations/reservations.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { Configv16Component } from './components/configv16/configv16.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { SettingsComponent } from './components/settings/settings.component';
import { DataSharingService } from './services/data-sharing.service';
import { TaskDetailsComponent } from './components/tasks/task-details/task-details/task-details.component';
import { LiveTransactionsComponent } from './components/live-transactions/live-transactions.component';
import { PastTransactionsComponent } from './components/past-transactions/past-transactions.component';
import { ScheduledReservationComponent } from './components/scheduled-reservation/scheduled-reservation.component';
import { PastReservationComponent } from './components/past-reservation/past-reservation.component';
import { ChangeAvailabilityComponent } from './components/configv16/change-availability/change-availability.component';
import { ChangeConfigurationComponent } from './components/configv16/change-configuration/change-configuration.component';
import { ClearCacheComponent } from './components/configv16/clear-cache/clear-cache.component';
import { RemoteStartTransactionComponent } from './components/configv16/remote-start-transaction/remote-start-transaction.component';
import { RemoteStopTransactionComponent } from './components/configv16/remote-stop-transaction/remote-stop-transaction.component';
import { ResetComponent } from './components/configv16/reset/reset.component';
import { UnlockConnectorComponent } from './components/configv16/unlock-connector/unlock-connector.component';
import { ReserveNowComponent } from './components/configv16/reserve-now/reserve-now.component';
import { CancelReservationComponent } from './components/configv16/cancel-reservation/cancel-reservation.component';
import { GetConfigurationComponent } from './components/configv16/get-configuration/get-configuration.component';
import { GetLocalListVersionComponent } from './components/configv16/get-local-list-version/get-local-list-version.component';
import { SendLocalListComponent } from './components/configv16/send-local-list/send-local-list.component';
import { TriggerMessageComponent } from './components/configv16/trigger-message/trigger-message.component';
import { LogsComponent } from './components/logs/logs.component';
import { ConnectorStatusComponent } from './components/connector-status/connector-status.component';
import { ConnectorDetailsComponent } from './components/connector-status/connector-details/connector-details.component';
import { ChargerGraphComponent } from './components/charger-graph/charger-graph.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    ChargePointComponent,
    UsersComponent,
    ReservationsComponent,
    TransactionsComponent,Configv16Component, TasksComponent, SettingsComponent, TaskDetailsComponent, LiveTransactionsComponent, PastTransactionsComponent, ScheduledReservationComponent, PastReservationComponent, ChangeAvailabilityComponent, ChangeConfigurationComponent, ClearCacheComponent, RemoteStartTransactionComponent, RemoteStopTransactionComponent, ResetComponent, UnlockConnectorComponent, ReserveNowComponent, CancelReservationComponent, GetConfigurationComponent, GetLocalListVersionComponent, SendLocalListComponent, TriggerMessageComponent, LogsComponent, ConnectorStatusComponent, ConnectorDetailsComponent, ChargerGraphComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    TableModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    CalendarModule,
    RadioButtonModule,
    DialogModule,
    AutoCompleteModule,
    MultiSelectModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CheckboxModule,
    NgxSpinnerModule,
    ChartModule
  ],
  providers: [DataSharingService,CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
