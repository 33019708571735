import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { TransactionsService } from 'src/app/services/transactions.service';

@Component({
  selector: 'app-remote-stop-transaction',
  templateUrl: './remote-stop-transaction.component.html',
  styleUrls: ['./remote-stop-transaction.component.css']
})
export class RemoteStopTransactionComponent implements OnInit {

  availabilityTypes: any[];
  vehicleNumber: any;

  constructor(private dataSharingService :DataSharingService,private configService: ConfigService,private transactionService: TransactionsService ,private router : Router) { }
  public charger ;
  public chargerList;
  public existingReservationList;
  public connectorId;
  public transactionIds=[];
  public transactionId;

  ngOnInit(): void {
    if(this.dataSharingService.chargerPoints.length == 0)
    this.configService.getChargers().subscribe(res =>{
      // console.log(res['response']['cpList'])
      this.dataSharingService.chargerPoints=[]
      res['response']['cpList'].forEach(charger => {
        this.dataSharingService.chargerPoints.push({label: charger.chargeBoxId, value: charger.chargeBoxId})
       });
      this.chargerList = this.dataSharingService.chargerPoints;
      this.charger = this.chargerList[0] ? this.chargerList[0].value : null;
    })
    else
    this.chargerList = this.dataSharingService.chargerPoints;
    this.charger = this.chargerList ? this.chargerList[0].value : null;
  this.existingReservationList =[]
  this.onSelect();
  }
  performAction(){
    let body ={
      "chargePointSelectList": [{ "chargeBoxId" : this.charger,"ocppTransport": "JSON"}],
      "transactionId": this.transactionId
    }
    this.configService.performRemoteStopTransaction(body).subscribe(res=>{
      this.router.navigateByUrl('/tasks/detail/'+res['response']);
    },error=>{
    })
  }
  onSelect(){
    this.configService.getActionTransactionByCharger(this.charger).subscribe(res=>{
      if(res['response']){
        this.transactionIds=[]
        this.transactionId = undefined
        res['response'].forEach(element => {
          this.transactionIds.push({label: element, value: element})
        });
        if(this.transactionIds.length >= 1)
          this.transactionId = this.transactionIds[0].value
      }
    })
  }
}
