import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TasksService } from 'src/app/services/tasks.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.css']
})
export class TaskDetailsComponent implements OnInit {

  constructor(private route: ActivatedRoute, private taskService: TasksService,private router : Router) { }
  @Input()
  taskId
  urlTaskId
  @Input()
  isSelected
  taskDetail
  timerId = null;
  cols
  data=[]
  private routeSub: Subscription;
  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.urlTaskId = params['taskId'] 
    });
    this.getTaskDetails()
    this.cols = [
      { field: 'chargeBox', header: 'Charger Box id',width : '400px' },
      { field: 'response', header: 'Response' },
      { field: 'errorMessage', header: 'Error'},]
  }
  ngOnDestroy() {
    clearInterval(this.timerId);
  }

  getTaskDetails(){
    if(this.urlTaskId)
       this.taskId = this.urlTaskId
   // console.log("TASK ID: ",this.taskId)
   this.taskService.getTaskDetail(this.taskId).subscribe(res=>{
    // console.log(res)
    if(res['response']){
    this.taskDetail = res['response']['task']
    this.taskDetail.noOfChargePoint = this.taskDetail.params.chargePointSelectList.length
    let result = JSON.parse(JSON.stringify(this.taskDetail.resultMap))
    this.data=[]
    for(let element in result){
        this.data.push({'chargeBox': element,'response': result[element]['response'] ? result[element]['response'] : null  , 'errorMessage': result[element]['errorMessage'] ? result[element]['errorMessage'] : null})
    }}
    else {
      Swal.fire({
        text:  res['errorMessage'],
        icon: 'warning',
        confirmButtonText: 'Return To Tasks',
      }).then((result) => {
        if (result.value) {
          this.router.navigateByUrl('/tasks');
          }
        })
    }
    if (!this.timerId) {
      this.timerId = setTimeout(() => {
        this.getTaskDetails();
        this.timerId = null;
      }, 20 * 1000);
    }
   },error =>{
      // console.log(error)
   })
  }

}
