import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { TransactionsService } from 'src/app/services/transactions.service';

@Component({
  selector: 'app-cancel-reservation',
  templateUrl: './cancel-reservation.component.html',
  styleUrls: ['./cancel-reservation.component.css']
})
export class CancelReservationComponent implements OnInit {

  constructor(private dataSharingService :DataSharingService,private configService: ConfigService, private transactionService: TransactionsService,private router : Router) { }
  public charger ;
  public chargerList;
  public existingReservationList;
  public reservationId;

  ngOnInit(): void {
  if(this.dataSharingService.chargerPoints.length == 0)
  { this.dataSharingService.chargerPoints=[]
    this.configService.getChargers().subscribe(res =>{
    res['response']['cpList'].forEach(charger => {
      this.dataSharingService.chargerPoints.push({label: charger.chargeBoxId, value: charger.chargeBoxId})
     });
    this.chargerList = this.dataSharingService.chargerPoints;
    this.charger = this.chargerList ? this.chargerList[0].value : null;
  })}
  else
  this.chargerList = this.dataSharingService.chargerPoints;
  if( this.charger)
   this.getActiveReservations()
  }
  performAction(){
    { let body = {
      "reservationId": this.reservationId ,
      "chargePointSelectList": [{"chargeBoxId": this.charger , "ocppTransport": "JSON"}]}
      // console.log(body)
      this.configService.performChangeAvailability(body).subscribe(res=>{
        // console.log(res)
        this.router.navigateByUrl('/tasks/detail/'+res['response']);
      },error=>{
        // console.log(error.error)
      })
    //   "chargePointSelectList": [
    //     {
    //       "chargeBoxId": "string",
    //       "endpointAddress": "string",
    //       "endpointAddressSet": true,
    //       "ocppTransport": "SOAP",
    //       "soap": true
    //     }
    //   ],
    //   "reservationId": 0
    // }
    
  }
 }
 getActiveReservations(){
  // console.log(this.charger)
  this.configService.getActiveReservationByCharger(this.charger).subscribe(res=>{
    // console.log(res)
    this.existingReservationList =[]
    if(res['status'] == 'SUCCESS')
     res['response'].forEach(reservation => {
       this.existingReservationList.push({label : reservation , value : reservation})
     });
     // console.log(this.existingReservationList)
  })
}
}
