import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';

@Component({
  selector: 'app-get-configuration',
  templateUrl: './get-configuration.component.html',
  styleUrls: ['./get-configuration.component.css']
})
export class GetConfigurationComponent implements OnInit {
  configurationKeys: { value: string; label: string; }[];

  constructor(private dataSharingService :DataSharingService,private configService: ConfigService,private router : Router) { }
  public charger ;
  public chargerList;
  public existingReservationList;
  public configurationKey;

  ngOnInit(): void {
    if(this.dataSharingService.chargerPoints.length == 0)
    this.configService.getChargers().subscribe(res =>{
      // console.log(res['response']['cpList'])
      this.dataSharingService.chargerPoints=[]
      res['response']['cpList'].forEach(charger => {
        this.dataSharingService.chargerPoints.push({label: charger.chargeBoxId, value: charger.chargeBoxId})
       });
      this.chargerList = this.dataSharingService.chargerPoints;
    })
    else
    this.chargerList = this.dataSharingService.chargerPoints;
  this.configurationKeys = [{value : "HeartbeatInterval", label : "HeartbeatInterval(in seconds)"}, 
                            {value : "AllowOfflineTxForUnknownId", label : "AllowOfflineTxForUnknownId(boolean)"}, 
                            {value : "AuthorizationCacheEnabled", label : "AuthorizationCacheEnabled(boolean)"}, 
                            {value : "AuthorizeRemoteTxRequests", label : "AuthorizeRemoteTxRequests(boolean)"}, 
                            {value : "ConnectorPhaseRotation", label : "ConnectorPhaseRotation(comma seperated list)"}, 
                            {value : "ConnectorPhaseRotationMaxLength", label : "ConnectorPhaseRotationMaxLength(integer)"},
                            {value : "GetConfigurationMaxKeys", label : "GetConfigurationMaxKeys(integer)"},
                            {value : "LocalAuthorizeOffline", label : "LocalAuthorizeOffline(boolean)"}, 
                            {value : "LocalPreAuthorize", label : "LocalPreAuthorize(boolean)"}, 
                            {value : "MaxEnergyOnInvalidId", label : "MaxEnergyOnInvalidId(in Wh)"}, 
                            {value : "MeterValuesAlignedDataMaxLength", label : "MeterValuesAlignedDataMaxLength(integer)"},
                            {value : "MeterValuesSampledDataMaxLength", label : "MeterValuesSampledDataMaxLength(integer)"},
                            {value : "MinimumStatusDuration", label : "MinimumStatusDuration(in seconds)"}, 
                            {value : "NumberOfConnectors", label : "NumberOfConnectors(integer)"},
                            {value : "StopTransactionOnEVSideDisconnect", label : "StopTransactionOnEVSideDisconnect(boolean)"}, 
                            {value : "StopTransactionOnInvalidId", label : "StopTransactionOnInvalidId(boolean)"}, 
                            {value : "StopTxnAlignedDataMaxLength", label : "StopTxnAlignedDataMaxLength(integer)"},
                            {value : "StopTxnSampledDataMaxLength", label : "StopTxnSampledDataMaxLength(integer)"},
                            {value : "SupportedFeatureProfiles", label : "SupportedFeatureProfiles(comma seperated list)"},
                            {value : "SupportedFeatureProfilesMaxLength", label : "SupportedFeatureProfilesMaxLength(integer)"},
                            {value : "TransactionMessageAttempts", label : "TransactionMessageAttempts(in times)"}, 
                            {value : "TransactionMessageRetryInterval", label : "TransactionMessageRetryInterval(in seconds)"}, 
                            {value : "UnlockConnectorOnEVSideDisconnect", label : "UnlockConnectorOnEVSideDisconnect(boolean)"}, 
                            {value : "WebSocketPingInterval", label : "WebSocketPingInterval(in seconds)"}, 
                            {value : "LocalAuthListEnabled", label : "LocalAuthListEnabled(boolean)"}, 
                            {value : "LocalAuthListMaxLength", label : "LocalAuthListMaxLength(integer)"},
                            {value : "SendLocalListMaxLength", label : "SendLocalListMaxLength(integer)"},
                            {value : "ReserveConnectorZeroSupported", label : "ReserveConnectorZeroSupported(boolean)"}]
  }
  performAction(){
    // {
    //   "chargePointSelectList": [
    //     {
    //       "chargeBoxId": "string",
    //       "endpointAddress": "string",
    //       "endpointAddressSet": true,
    //       "ocppTransport": "SOAP",
    //       "soap": true
    //     }
    //   ],
    //   "confKeyList": [
    //     "string"
    //   ],
    //   "setConfKeyList": true
    // }
    let body = {
      "chargePointSelectList": [],
      "confKeyList":[],
      "setConfKeyList": false
    }
    this.charger.forEach(charger => {
      body['chargePointSelectList'].push({ "chargeBoxId": charger , "ocppTransport": "JSON"})
    });
    this.configurationKey.forEach(config => {
      body['setConfKeyList'] = true
      body['confKeyList'].push(config)
    });
    // console.log(body)
    this.configService.performGetConfig(body).subscribe(res=>{
      // console.log(res)
      this.router.navigateByUrl('/tasks/detail/'+res['response']);
    },error=>{
      // console.log(error.error)
    })
  }

}
