import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, pipe } from 'rxjs';
import { DataSharingService } from '../services/data-sharing.service';

import { COOKIE_URL } from '../config';

@Injectable({
  providedIn: 'root'
})
export class CanActivateRouteGuard implements CanActivate {
  constructor(private cookieService: CookieService, private router: Router, private dataSharingService : DataSharingService) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const context = this;
    let rolesURL = {"MENU_HOME" : "/home",
                    "MENU_CHARGE_POINT" : "/charge-point",
                    "MENU_USERS" : "/users",
                    "MENU_LIVE_TRANSACTION" : "/live-transactions",
                    "MENU_PAST_TRANSACTION" : "/past-transactions",
                    "MENU_SCHEDULED_RESERVATION" : "/reservations",
                    "MENU_PAST_RESERVATION" : "/past-reservations",
                    "MENU_CONFIG" : "/configv1.6",
                    "MENU_TASK" : "/tasks",
                    "MENU_TASK_DETAILS" :"",
                    "MENU_SETTING" : "/settings"}
    let userMenuRoles={}
    return new Promise(function canActivate(resolve, reject) {
      // console.log("dataSharing")
      // console.log(context.dataSharingService)
      context.dataSharingService.checkToken().subscribe((responseServer: any) => {
        // console.log("Response Server",responseServer);
        if (responseServer.statusCode >= 300) {
          //this.dataSharingService.isUserLoggedIn.next(false);
          //// console.log("Can activate")
          context.cookieService.delete('accessToken', '/', COOKIE_URL);
          context.router.navigateByUrl('/home');
          context.dataSharingService.isUserLoggedIn.next(false);
          return reject(false);
        }
        else {
          let ssoId = responseServer.response.sso_id
          let lastRole
          let roleExist
          localStorage.setItem('ssoId', responseServer.response.sso_id)
          context.dataSharingService.getRolesForUser(ssoId).subscribe(
            (res: any) => {
              localStorage.setItem("userRoles", JSON.stringify(res.response))
              res.response.forEach(role => {
                if(JSON.stringify(role).indexOf("MENU") !== -1)
                  { userMenuRoles[rolesURL[role]]=role
                    lastRole=rolesURL[role]
                    if(next['_routerState'].url.includes(rolesURL[role]))
                     { roleExist =true;
                       return resolve(true);
                      }
                  }
              });
              if(!roleExist && !userMenuRoles[next['_routerState'].url])
               { context.router.navigateByUrl(lastRole);
                 return reject(false); 
               }
            }
          )
        }
        return resolve(true);

      }, (error) => { });
    });
  }

}
