import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ChargePointService } from 'src/app/services/charge-point.service';
import { HomeService } from 'src/app/services/home.service';
import Swal from 'sweetalert2';
import { country } from '../../../model/country';

@Component({
  selector: 'app-connector-details',
  templateUrl: './connector-details.component.html',
  styleUrls: ['./connector-details.component.css']
})
export class ConnectorDetailsComponent implements OnInit {

  constructor(private chargePointService: ChargePointService ,private route: ActivatedRoute, private router : Router,private homeSerivce: HomeService) { }
  private routeSub: Subscription;
  countryList
  connectorPk
  chargePointForm =  {
    "address": {
      "city": "",
      "country": "",
      "houseNumber": "",
      "street": "",
      "zipCode": ""
    },
    "chargeBoxId": "",
    "description": "",
    "locationLatitude": 0,
    "locationLongitude": 0,
    "note": "",
    "tenantId": 1
  }
  selectedCountry = 'IN'
  chargeBox={}
  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.connectorPk = params['chargeBoxPk'] 
    });
    this.countryList = country;
    this.getConnectorDetails()
  }
  getConnectorDetails(){
    this.homeSerivce.getConnectorDetails(this.connectorPk).subscribe(res=>{
      if(res['response'])
      {this.chargePointForm = res['response']['chargePointForm']
      this.chargeBox = res['response']['cp']['chargeBox']
      }else{
        Swal.fire(
          'Some thing went Wrong!',
          res['errorMessage'],
          'error'
        )
        this.router.navigateByUrl('/home');
      }
    })
  }
  update(){
    this.chargePointForm.address.country =this.selectedCountry;
    this.chargePointForm.tenantId = 1;
    Swal.fire({
      title: 'Are you sure you want to update ?',
      // text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Update it!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
    this.chargePointService.update(this.chargePointForm).subscribe(res=>{
      if(res['errorCode']==200)
     {  
      Swal.fire(
        'Success!',
        'ChargeBox Updated successfully',
        'success'
      )
      this.getConnectorDetails();
      }
      else
      {  Swal.fire(
        'Some thing went Wrong!',
        res['errorMessage'],
        'error'
      )
      }
    },error=>{
      // console.log(error)
    })}
  })



  }

}
