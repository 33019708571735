import { BoundTextAst } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';

@Component({
  selector: 'app-trigger-message',
  templateUrl: './trigger-message.component.html',
  styleUrls: ['./trigger-message.component.css']
})
export class TriggerMessageComponent implements OnInit {
  triggerMessages: { label: string; value: string; }[];
  public triggerMessage
  public charger;
  public chargerList;
  public connectorId;

  constructor(private dataSharingService :DataSharingService,private configService: ConfigService,private router : Router) { }

  ngOnInit(): void {
    if(this.dataSharingService.chargerPoints.length == 0)
    this.configService.getChargers().subscribe(res =>{
      // console.log(res['response']['cpList'])
      this.dataSharingService.chargerPoints=[]
      res['response']['cpList'].forEach(charger => {
        this.dataSharingService.chargerPoints.push({label: charger.chargeBoxId, value: charger.chargeBoxId})
       });
      this.chargerList = this.dataSharingService.chargerPoints;
    })
    else
    this.chargerList = this.dataSharingService.chargerPoints;
    this.triggerMessages =[{label : "BootNotification" , value: "BootNotification"},
    {label : "DiagnosticsStatusNotification" , value: "DiagnosticsStatusNotification"},
    {label : "FirmwareStatusNotification" , value: "FirmwareStatusNotification"},
    {label : "Heartbeat" , value: "Heartbeat"},
    {label : "MeterValues" , value: "MeterValues"},
    {label : "StatusNotification" , value: "StatusNotification"}]
  }
  performAction(){
    // {
    //   "chargePointSelectList": [
    //     {
    //       "chargeBoxId": "string",
    //       "endpointAddress": "string",
    //       "endpointAddressSet": true,
    //       "ocppTransport": "SOAP",
    //       "soap": true
    //     }
    //   ],
    //   "connectorId": 0,
    //   "triggerMessage": "BootNotification"
    // }
    let body = {
      "chargePointSelectList":[],
      "connectorId": this.connectorId,
      "triggerMessage": this.triggerMessage
    }
    this.charger.forEach(charger => {
      body['chargePointSelectList'].push({ "chargeBoxId": charger , "ocppTransport": "JSON"})
    });
    // console.log(body)
    this.configService.performReset(body).subscribe(res=>{
      // console.log(res)
      this.router.navigateByUrl('/tasks/detail/'+res['response']);
    },error =>{
      // console.log(error.error)
    })
  }

}
