<div style="padding: 40px;">
    
    <div class="p-formgroup-inline">
        <div class="p-field" >
            <p-dropdown appendTo="body" (onChange)="getGraphData()" [options]="chargerList" [(ngModel)]="charger"></p-dropdown>
        </div>
        <div class="p-field" *ngIf= "charger == 'ALL'" >
            <p-dropdown appendTo="body" (onChange)="getGraphData()" [options]="hubs" [(ngModel)]="hub"></p-dropdown>
        </div>
        <div class="p-field" >
            <p-dropdown appendTo="body" (onChange)="getGraphData()" [options]="accessTypes" [(ngModel)]="accessType"></p-dropdown>
        </div>

        <div class="p-field"  *ngIf="accessType == 'DAILY'">
           <div>
            <p-calendar appendTo="body" (onSelect)="getGraphData()" [(ngModel)]="selectedDates" [showIcon]="true" selectionMode="range"></p-calendar>
           </div>
        </div>
        <div class="p-field"  *ngIf="accessType == 'HOURLY'">
            <div>
             <p-calendar appendTo="body" (onSelect)="getGraphData()" [(ngModel)]="selectedDate" [showIcon]="true"></p-calendar>
            </div>
         </div>
         <!-- <div class="p-field">
            <div>
             <button class="btn btn-primary" (click)="getGraphData()">Submit</button>
            </div>
         </div> -->
    </div>
    <p-chart type="line" #linechart [data]="basicData" [options]="basicOptions" height="60vh"></p-chart>
</div>
