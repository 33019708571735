import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';

@Component({
  selector: 'app-change-availability',
  templateUrl: './change-availability.component.html',
  styleUrls: ['./change-availability.component.css']
})
export class ChangeAvailabilityComponent implements OnInit {
  availabilityTypes: any[];

  constructor(private dataSharingService :DataSharingService,private configService: ConfigService,private router : Router) { }
  public charger ;
  public chargerList;
  public existingReservationList;
  public connectorId;
  public availabilityType="INOPERATIVE";

  ngOnInit(): void {
    if(this.dataSharingService.chargerPoints.length == 0)
    this.configService.getChargers().subscribe(res =>{
      this.dataSharingService.chargerPoints=[]
      res['response']['cpList'].forEach(charger => {
        this.dataSharingService.chargerPoints.push({label: charger.chargeBoxId, value: charger.chargeBoxId})
       });
      this.chargerList = this.dataSharingService.chargerPoints;
    })
    else
    this.chargerList = this.dataSharingService.chargerPoints;
  this.existingReservationList =[]
  this.availabilityTypes = [{label: 'Inoperative', value: 'INOPERATIVE'},
                            {label: 'Operative', value: 'OPERATIVE'}]
  }
  performAction(){
    let body = {
      "availType": this.availabilityType,
      "chargePointSelectList": []
    }
    console.log(this.dataSharingService.hub)
    this.charger.forEach(charger => {
      body['chargePointSelectList'].push({ "chargeBoxId": charger , "ocppTransport": "JSON"})
    });
    body['connectorId']= this.connectorId

    // console.log("body: ",body)
    this.configService.performChangeAvailability(body).subscribe(res=>{
      // console.log(res)
      this.router.navigateByUrl('/tasks/detail/'+res['response']);
    },error=>{
      // console.log(error.error)
    })
    // {
    //   "availType": "INOPERATIVE",
    //   "chargePointSelectList": [
    //     {
    //       "chargeBoxId": "string",
    //       "endpointAddress": "string",
    //       "endpointAddressSet": true,
    //       "ocppTransport": "SOAP",
    //       "soap": true
    //     }
    //   ],
    //   "connectorId": 0
    // }
  }
}
