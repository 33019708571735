import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-connector-status',
  templateUrl: './connector-status.component.html',
  styleUrls: ['./connector-status.component.css']
})
export class ConnectorStatusComponent implements OnInit {

  constructor(private homeSerivce: HomeService,private spinner: NgxSpinnerService,private router : Router) { }
  public cols
  public data
  ngOnInit(): void {
    this.spinner.show()
    this.homeSerivce.getConnectors().subscribe(res=>{
      if(res['status']=="SUCCESS")
      this.data=res['response']
      this.spinner.hide()
    })
    this.cols = [
      { field: 'chargeBoxId', header: 'ChargeBoxId' },
      //{ field: 'createdTimeStamp', header: 'Created Time' },
      { field: 'connectorId', header: 'Connector Id'},
      { field: 'timeStamp', header: 'Date/Time' },
      { field: 'status', header: 'Status'},
      { field: 'errorCode', header: 'Error Code'},
  ];
  }
  routeTo(chargeBoxPk){
    this.router.navigateByUrl('/charger-details/'+chargeBoxPk);
  }

}
