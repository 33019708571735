import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { DataSharingService } from '../../../services/data-sharing.service';

@Component({
  selector: 'app-change-configuration',
  templateUrl: './change-configuration.component.html',
  styleUrls: ['./change-configuration.component.css']
})
export class ChangeConfigurationComponent implements OnInit {

  availabilityTypes: any[];
  keyTypes: { label: string; value: string; }[];
  configurationKeys: { value: string; label: string; }[];

  constructor(private dataSharingService :DataSharingService,private configService: ConfigService ,private router : Router) { }
  public charger ;
  public chargerList;
  public existingReservationList;
  public connectorId;
  public availabilityType;
  public keyType = 'PREDEFINED'
  public configurationKey ;
  public customConfigurationKey;
  public value;

  ngOnInit(): void { 
  if(this.dataSharingService.chargerPoints.length == 0)
  this.configService.getChargers().subscribe(res =>{
    // console.log(res['response']['cpList'])
    this.dataSharingService.chargerPoints=[]
    res['response']['cpList'].forEach(charger => {
      this.dataSharingService.chargerPoints.push({label: charger.chargeBoxId, value: charger.chargeBoxId})
     });
    this.chargerList = this.dataSharingService.chargerPoints;
  })
  else
  this.chargerList = this.dataSharingService.chargerPoints;
   
  // console.log(this.chargerList)
  this.keyTypes = [{label: 'Predefined', value: 'PREDEFINED'},
                  {label: 'Custom', value: 'CUSTOM'}]
  this.availabilityTypes = [{label: 'Inoperative', value: 'INOPERATIVE'},
                            {label: 'Operative', value: 'OPERATIVE'}]
  this.configurationKeys = [{value : "HeartbeatInterval", label : "HeartbeatInterval(in seconds)"}, 
                            {value : "AllowOfflineTxForUnknownId", label : "AllowOfflineTxForUnknownId(boolean)"}, 
                            {value : "AuthorizationCacheEnabled", label : "AuthorizationCacheEnabled(boolean)"}, 
                            {value : "AuthorizeRemoteTxRequests", label : "AuthorizeRemoteTxRequests(boolean)"}, 
                            {value : "ConnectorPhaseRotation", label : "ConnectorPhaseRotation(comma seperated list)"}, 
                            {value : "ConnectorPhaseRotationMaxLength", label : "ConnectorPhaseRotationMaxLength(integer)"},
                            {value : "GetConfigurationMaxKeys", label : "GetConfigurationMaxKeys(integer)"},
                            {value : "LocalAuthorizeOffline", label : "LocalAuthorizeOffline(boolean)"}, 
                            {value : "LocalPreAuthorize", label : "LocalPreAuthorize(boolean)"}, 
                            {value : "MaxEnergyOnInvalidId", label : "MaxEnergyOnInvalidId(in Wh)"}, 
                            {value : "MeterValuesAlignedDataMaxLength", label : "MeterValuesAlignedDataMaxLength(integer)"},
                            {value : "MeterValuesSampledDataMaxLength", label : "MeterValuesSampledDataMaxLength(integer)"},
                            {value : "MinimumStatusDuration", label : "MinimumStatusDuration(in seconds)"}, 
                            {value : "NumberOfConnectors", label : "NumberOfConnectors(integer)"},
                            {value : "StopTransactionOnEVSideDisconnect", label : "StopTransactionOnEVSideDisconnect(boolean)"}, 
                            {value : "StopTransactionOnInvalidId", label : "StopTransactionOnInvalidId(boolean)"}, 
                            {value : "StopTxnAlignedDataMaxLength", label : "StopTxnAlignedDataMaxLength(integer)"},
                            {value : "StopTxnSampledDataMaxLength", label : "StopTxnSampledDataMaxLength(integer)"},
                            {value : "SupportedFeatureProfiles", label : "SupportedFeatureProfiles(comma seperated list)"},
                            {value : "SupportedFeatureProfilesMaxLength", label : "SupportedFeatureProfilesMaxLength(integer)"},
                            {value : "TransactionMessageAttempts", label : "TransactionMessageAttempts(in times)"}, 
                            {value : "TransactionMessageRetryInterval", label : "TransactionMessageRetryInterval(in seconds)"}, 
                            {value : "UnlockConnectorOnEVSideDisconnect", label : "UnlockConnectorOnEVSideDisconnect(boolean)"}, 
                            {value : "WebSocketPingInterval", label : "WebSocketPingInterval(in seconds)"}, 
                            {value : "LocalAuthListEnabled", label : "LocalAuthListEnabled(boolean)"}, 
                            {value : "LocalAuthListMaxLength", label : "LocalAuthListMaxLength(integer)"},
                            {value : "SendLocalListMaxLength", label : "SendLocalListMaxLength(integer)"},
                            {value : "ReserveConnectorZeroSupported", label : "ReserveConnectorZeroSupported(boolean)"}]
  }
  performAction(){
    let body = {
      "chargePointSelectList":[],
       "confKey": this.configurationKey,
       "customConfKey": this.customConfigurationKey,
       "key":this.configurationKey,
       "ketType": this.keyType,
       "value": this.value,
       "validCustom": this.keyType == "CUSTOM",
       "validPredefined": this.keyType == "PREDEFINED"
    }
    this.charger.forEach(charger => {
      body['chargePointSelectList'].push({ "chargeBoxId": charger , "ocppTransport": "JSON"})
    });
    // console.log(body)
    this.configService.performChangeConfiguration(body).subscribe(res=>{
      // console.log(res)
      this.router.navigateByUrl('/tasks/detail/'+res['response']);
    },error =>{
      // console.log(error)
    })

    // {
    //   "chargePointSelectList": [
    //     {
    //       "chargeBoxId": "string",
    //       "endpointAddress": "string",
    //       "endpointAddressSet": true,
    //       "ocppTransport": "SOAP",
    //       "soap": true
    //     }
    //   ],
    //   "confKey": "string",
    //   "customConfKey": "string",
    //   "key": "string",
    //   "keyType": "PREDEFINED",
    //   "validCustom": true,
    //   "validPredefined": true,
    //   "value": "string"
    // }
    
  }
}
