<div class="header">
    <h4><span> Charger Details </span></h4>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">ChargeBox ID:</div></label>
    <div class="p-col">
        <input id="name" type="text" [(ngModel)]="chargePointForm.chargeBoxId" pInputText> 
    </div>
</div>

<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Endpoint Address:</div></label>
    <div class="p-col">
        <span *ngIf="chargeBox">{{chargeBox.endpointAddress}}</span>
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Ocpp Protocol:	</div></label>
    <div class="p-col">
        <span *ngIf="chargeBox">{{chargeBox.ocppProtocol}}</span>
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Charge Point Vendor:	</div></label>
    <div class="p-col">
        <span *ngIf="chargeBox">{{chargeBox.chargePointVendor}}</span>
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Charge Point Model:	</div></label>
    <div class="p-col">
        <span *ngIf="chargeBox">{{chargeBox.chargePointModel}}</span>
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Charge Point Serial Number:</div></label>
    <div class="p-col">
        <span *ngIf="chargeBox">{{chargeBox.chargePointSerialNumber}}</span>
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Charge Box Serial Number:	</div></label>
    <div class="p-col">
        <span *ngIf="chargeBox">{{chargeBox.chargeBoxSerialNumber}}</span>
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Firewire Version:	</div></label>
    <div class="p-col">
        <span *ngIf="chargeBox">{{chargeBox.fwVersion}}</span>
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Firewire Update Timestamp:	</div></label>
    <div class="p-col">
        <span *ngIf="chargeBox">{{chargeBox.fwUpdateTimestamp}}</span>
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Iccid:	</div></label>
    <div class="p-col">
        <span *ngIf="chargeBox">{{chargeBox.iccid}}</span> 
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Imsi:</div></label>
    <div class="p-col">
        <span *ngIf="chargeBox">{{chargeBox.imsi}}</span> 
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Meter Type:	</div></label>
    <div class="p-col">
        <span *ngIf="chargeBox"> {{chargeBox.meterType}}</span>
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Meter Serial Number:</div></label>
    <div class="p-col">
        <span *ngIf="chargeBox">{{chargeBox.meterSerialNumber}}</span>
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Diagnostics Status:	</div></label>
    <div class="p-col">
        <span *ngIf="chargeBox">{{chargeBox.diagnosticStatus}}</span>
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Diagnostics Timestamp:	</div></label>
    <div class="p-col">
        <span *ngIf="chargeBox">{{chargeBox.diagnosticTimestamp}}</span>
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Last Hearbeat Timestamp: </div></label>
    <div class="p-col">
        <span *ngIf="chargeBox">{{chargeBox.lastHeartbeatTimestamp}}</span>
    </div>
</div>




<h5 style="font-weight: 700;  color:var(--white); padding: 20px;text-align: center; ">Address</h5>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Street:</div></label>
    <div class="p-col">
        <input id="email" type="text" [(ngModel)]="chargePointForm.address.street" pInputText> 
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">House Number:</div></label>
    <div class="p-col">
        <input id="phone" type="text" [(ngModel)]="chargePointForm.address.houseNumber" pInputText> 
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Zip code:</div></label>
    <div class="p-col">
        <input id="sex" type="text" [(ngModel)]="chargePointForm.address.zipCode" pInputText> 
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">City:</div></label>
    <div class="p-col">
        <input id="additionalNote" type="text" [(ngModel)]="chargePointForm.address.city" pInputText> 
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Country:</div></label>
    <div class="p-col">
        <p-dropdown [options]="countryList" [(ngModel)]="selectedCountry"></p-dropdown>
    </div>
</div>
<h5 style="font-weight: 700;  color:var(--white); padding: 20px;text-align: center; ">Misc</h5>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Description:</div></label>
    <div class="p-col">
        <input id="additionalNote" type="text" [(ngModel)]="chargePointForm.description" pInputText> 
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Latitude:</div></label>
    <div class="p-col">
        <input id="additionalNote" type="text" [(ngModel)]="chargePointForm.locationLatitude"  pInputText> 
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Longitude:</div></label>
    <div class="p-col">
        <input id="additionalNote" type="text" [(ngModel)]="chargePointForm.locationLongitude" pInputText> 
    </div>
</div>
<div class="p-field p-grid ">
    <label for="heartbeat" class="p-col-5"> <div style="width: 100%;text-align: right;color: var(--white);">Additional Note:</div></label>
    <div class="p-col">
        <input id="additionalNote" type="text" [(ngModel)]="chargePointForm.note" pInputText> 
    </div>
</div>
<div class="p-field p-grid">
    <label for="recipients" class="p-col-6" ><div style="width: 100%;text-align: right;color: var(--white);"></div></label>
    <div class="p-col">
        <button type="submit" class="btn btn-primary submit" (click)="update()">Update</button>
    </div>
</div>