import { Injectable } from '@angular/core';
import { Headers } from '../model/api.headers';
import { HttpClient } from '@angular/common/http';
import { API_FETCH_FUTURE_RESERVATION,API_FETCH_FUTURE_TRANSACTION,API_FETCH_PAST_TRANSACTION,API_STOP_TRANSACTION} from '../config/index';
import { DataSharingService } from './data-sharing.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  constructor(private http: HttpClient, private dataSharingService : DataSharingService, private cookieService: CookieService) { }

  getScheduleTransaction(){
    let url = API_FETCH_FUTURE_RESERVATION
    let hub =  this.cookieService.get('hub')
    if(hub && hub != '0' )
      url = url+'&stationId='+hub
    return this.http.get(url,{headers: Headers.headers})
  }
  getPastTransaction(from,to){
    let url = API_FETCH_PAST_TRANSACTION+'?from='+from+'&to='+to
    let hub =  this.cookieService.get('hub')
    if(hub && hub != '0' )
      url = url+'&stationId='+hub
    return this.http.get(url,{headers: Headers.headers})
  }
  getLiveTransaction(){
    let url = API_FETCH_FUTURE_TRANSACTION
    let hub =  this.cookieService.get('hub')
    if(hub && hub != '0' )
      url = url+'&stationId='+hub
    return this.http.get(url,{headers: Headers.headers})
  }
  stopTransaction(transactionId){
    return this.http.get(API_STOP_TRANSACTION+transactionId,{headers: Headers.headers})
  }
}
